import React, { useState } from 'react';
import { Container, Row, Col, Table, Button, Form, Image } from 'react-bootstrap';
import './QualityTesting.css';
import { Link } from 'react-router-dom';

import NestedTable from '../../common/NestedTable/NestedTable';

export default function QualityTesting() {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  const testsData = [
    {
      id: '1',
      name: 'Helmet',
      lastUpdated: '05.13.2023',
      program: 'SolidWorks, Blender',
      fileType: 'obj, stl',
      creator: 'Michelle, Ashley',
      subItems: [
        {
          id: '1-1',
          name: 'Helmet Shell',
          lastUpdated: '05.13.2023',
          program: 'SolidWorks',
          fileType: 'obj',
          creator: 'Michelle P',
          subItems: [
            {
              id: '1-1-1',
              name: 'Visor',
              details: [
                { id: '1-1-1-1', name: 'Flex Test', lastUpdated: '05.13.2023', program: 'SolidWorks', fileType: 'obj', creator: 'Michelle P' },
                { id: '1-1-1-2', name: 'Shade Test', lastUpdated: '01.10.2022', program: 'Blender', fileType: 'stl', creator: 'Ashley K' }
              ]
            }
          ]
        }
      ]
    },
    {
      id: '2',
      name: 'Boots',
      lastUpdated: '05.18.2023',
      program: 'AutoCAD',
      fileType: 'obj, stl',
      creator: 'Alex L',
    },
    {
      id: '3',
      name: 'Chest Piece',
      lastUpdated: '01.10.2022',
      program: 'AutoCAD, Blender',
      fileType: 'obj, stl',
      creator: 'Michelle P',
    },
    {
      id: '4',
      name: 'Helmet Lighting Design',
      lastUpdated: '05.18.2023',
      program: 'Blender',
      fileType: 'stl',
      creator: 'Michelle P',
    },
    {
      id: '5',
      name: 'Gauntlet Design',
      lastUpdated: '01.10.2022',
      program: 'Fusion 360, Inventor',
      fileType: 'obj, stl',
      creator: 'Alex L',
    }
  ];

  return (
    <Container fluid className="quality-testing-container">
      {/* Test Options and Previous Tests Section */}
      <Row className="top-section">
        <Col md={6}>
          <div className="test-options mt-5">
            <Link to="/admin/quality-testing-option" className='text-white'>
              <div className="option-card border">
                <Image src={require('../../../assets/admin/quality-testing/ConductTest.png')} className="mt-5 mb-5" />
                <p>Conduct a Test</p>
              </div>
            </Link>

            <Link to="/admin/quality-testing-manually" className='text-white'>
              <div className="option-card border">
                <Image src={require('../../../assets/admin/quality-testing/ManuallyTest.png')} className='mt-5 mb-5' ></Image>
                <p>Manually add a Test</p>
              </div>
            </Link>
            
            <Link to="/admin/quality-testing-manually" className='text-white'>
            <div className="option-card border">
              <Image src={require('../../../assets/admin/quality-testing/DragDropTest.png')} className='mt-5 mb-5'></Image>
              <p>Drag and Drop Test</p>
            </div>
            </Link>
          </div>
        </Col>
        <Col md={6}>
          <div className="section-header">
            <p>Previous Tests</p>
            <Row>
              <Col md={6}>
                <div className=""><i className='fas fa-plus h6 border rounded p-1'></i></div>
              </Col>
              <Col md={6}>
                <div className=""><i className='fas fa-edit h6 border rounded p-1'></i></div>
              </Col>
            </Row>
          </div>
          <div className="quality-testing-section-divider"></div>
          <div className="previous-tests">
            <div className="image-card">
              <img src={require('../../../assets/admin/quality-testing/VisorFlexTest.png')} className="test-image" alt="Visor Flex Test" />
              <div className="image-overlay">
                <div className="image-text">Visor Flex Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/quality-testing/PressureTest.png')} className="test-image" alt="Pressure Test" />
              <div className="image-overlay">
                <div className="image-text">Pressure Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/quality-testing/TestImage3.png')} className="test-image" alt="Pressure Test" />
              <div className="image-overlay">
                <div className="image-text"></div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/quality-testing/StructuralAnalysis.png')} className="test-image" alt="Structural Analysis" />
              <div className="image-overlay">
                <div className="image-text">Structural Analysis</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <div className='text-center'>
        <p>Fatigue Test</p>
      </div>
      {/* Tests Section */}
      <Row className="tests-section">
        <Col md={12}>
          <div className="section-header">
            <h4>Tests</h4>
            <div className="quality-table-actions mt-3">
              <button className="btn btn-outline-light m-1 w-50">Edit</button>
              <input type="text" placeholder="Search" className="tests-search" />
              <button className="btn btn-outline-light"><i className='fas fa-bars'></i></button>
              <button className="btn btn-outline-light"><i className='fas fa-download'></i></button>
            </div>
          </div>
          <div className="quality-testing-section-divider"></div>
          <NestedTable />

          {/* <Table responsive className="tests-table">
            <thead>
              <tr>
                <th></th>
                <th>Tests</th>
                <th>Last Updated</th>
                <th>Program</th>
                <th>File Type</th>
                <th>Creator</th>
              </tr>
            </thead>
            <tbody>
              {testsData.map((test, index) => (
                <React.Fragment key={test.id}>
                  <tr>
                    <td><Form.Check type="checkbox" /></td>
                    <td onClick={() => toggleRow(test.id)} style={{ cursor: 'pointer' }}>
                      {expandedRows[test.id] ? '▼' : '▶'} {test.name}
                    </td>
                    <td>{test.lastUpdated}</td>
                    <td>{test.program}</td>
                    <td>{test.fileType}</td>
                    <td>{test.creator}</td>
                  </tr>
                  {expandedRows[test.id] && test.subItems && test.subItems.map((subItem) => (
                    <React.Fragment key={subItem.id}>
                      <tr className="sub-item">
                        <td></td>
                        <td onClick={() => toggleRow(subItem.id)} style={{ cursor: 'pointer' }}>
                          {expandedRows[subItem.id] ? '▼' : '▶'} {subItem.name}
                        </td>
                        <td>{subItem.lastUpdated}</td>
                        <td>{subItem.program}</td>
                        <td>{subItem.fileType}</td>
                        <td>{subItem.creator}</td>
                      </tr>
                      {expandedRows[subItem.id] && subItem.subItems && subItem.subItems.map((innerItem) => (
                        <React.Fragment key={innerItem.id}>
                          {innerItem.details && innerItem.details.map((detail) => (
                            <tr className="inner-item" key={detail.id}>
                              <td></td>
                              <td>{'>'} {detail.name}</td>
                              <td>{detail.lastUpdated}</td>
                              <td>{detail.program}</td>
                              <td>{detail.fileType}</td>
                              <td>{detail.creator}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table> */}
        </Col>
      </Row>
    </Container>
  );
}
