import React from 'react';
import { Modal, Button, Image } from 'react-bootstrap';
import './X-EvasModal.css';  // We'll create this file next

export default function XEvasModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="x-evas-modal">
      <Modal.Header closeButton>
        <Modal.Title>X-EVAS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={require('../../../../../../assets/admin/design/modal-images/NasaImage.png')} alt="NASA Logo" className="nasa-logo" />  {/* Replace with the actual path to the NASA logo */}
        <p>The NASA Exploration Extravehicular Mobility Unit (EMU) spacesuit is designed to support missions to the International Space Station (ISS), the Moon, and eventually Mars.</p>
        <hr />
        <h5>Area:</h5>
        <p>Spacesuits</p>
        <h5>Key Features and Requirements</h5>
        <ul>
          <li>Mobility: Enhanced joint bearings and soft elements allow greater flexibility, crucial for performing complex tasks on the lunar surface and during spacewalks.</li>
          <li>Durability: Constructed to withstand harsh space conditions, including micrometeoroid impacts, and extreme temperatures ranging from -292°F to +220°F.</li>
          <li>Life Support: Advanced systems for temperature regulation, oxygen supply, and carbon dioxide removal ensure astronaut safety during extended extravehicular activities (EVAs).</li>
        </ul>
        <h5>Testing and Certification</h5>
        <ul>
          <li>Thermal Vacuum Testing: Conducted in environments simulating the vacuum and temperature extremes of space to ensure suit integrity and life support system functionality.</li>
          <li>Pressure Testing: Evaluates the suit's ability to maintain pressure and protect against decompression.</li>
          <li>Mobility and Fit Testing: Ensures the suit accommodates a wide range of body sizes and allows for the necessary range of motion.</li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
