import React from 'react';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Maintenance.css'; // Custom styles

const localizer = momentLocalizer(moment);

const Maintenance = () => {
  const events = [
    // Your event data here
  ];

  return (
    <Container fluid className="maintenance-container">
      <Row className="h-100">
        <Col md={6} className="d-flex flex-column h-100">
          <div className="maintenance-form flex-grow-1 mb-4">
            <h5>Maintenance Request</h5>
            <Form>
            <Form.Group controlId="formPiece">
              <Form.Label>Piece</Form.Label>
              <Form.Control type="text" placeholder="Gauntlet" />
            </Form.Group>

            <Form.Group controlId="formID">
              <Form.Label>ID#</Form.Label>
              <Form.Control type="text" placeholder="#2903" />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="abcde@metakosmos.com.au" />
            </Form.Group>

            <Form.Group controlId="formPriority" className='mt-2'>
              <Form.Label>Priority Level</Form.Label>
              <div className="priority-buttons">
                <Button variant="outline-light rounded-pill m-2 w-25">Low</Button>
                <Button variant="outline-warning rounded-pill m-2 w-25">Medium</Button>
                <Button variant="outline-danger rounded-pill w-25">High</Button>
              </div>
            </Form.Group>

            <Form.Group controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={3} maxLength="500" placeholder="Enter details here..." />
              <Form.Text className="text-muted">0/500</Form.Text>
            </Form.Group>

            <div className="form-buttons justify-content-end">
              <Button variant="outline-light">Clear</Button>
              &nbsp;&nbsp;
              <Button variant="primary">Save</Button>
            </div>
          </Form>
          </div>

          <div className="service-history flex-grow-1">
            <h5>Service History and Logs</h5>
            <Table striped bordered hover variant="dark" className="history-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <th>Issue</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Helmet Assembly</td>
                <td>03.11.23</td>
                <td>"Vyom Boot"</td>
                <td><span className="status-complete">✔</span></td>
              </tr>
              <tr>
                <td>Outer Shell Construction</td>
                <td>12.10.23</td>
                <td>"Vyom Boot"</td>
                <td><span className="status-complete">✔</span></td>
              </tr>
              <tr>
                <td>Thermal Insulation Layering</td>
                <td>06.28.23</td>
                <td>"Vyom Boot"</td>
                <td><span className="status-complete">✔</span></td>
              </tr>
              <tr>
                <td>Quality Control and Testing</td>
                <td>01.30.23</td>
                <td>"Vyom Boot"</td>
                <td><span className="status-complete">✔</span></td>
              </tr>
              <tr>
                <td>Packaging Shipping Preparation</td>
                <td>09.01.23</td>
                <td>"Vyom Boot"</td>
                <td><span className="status-pending">⏳</span></td>
              </tr>
            </tbody>
          </Table>
          </div>
        </Col>

        <Col md={6} className="maintenance-calendar d-flex flex-column h-100">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={new Date(2024, 7, 1)}
            style={{ flexGrow: 1 }}
            views={['month']}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Maintenance;
