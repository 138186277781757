import React, { useState, useRef } from 'react';
import './Dashboard.css'; // Add custom CSS here for styling

export default function Dashboard() {
    
    const items = [
        { name: "Sensor Suit", color: "#8a2be2", background: '#8A34FF33'},
        { name: "Bladder", color: "#8a2be2" ,background: '#8A34FF33'},
        { name: "Exoskeleton", color: "#ff0000", background: '#FF344C4D'},
        { name: "Exosuits", color: "#8a2be2", background: '#8A34FF33' },
        { name: "Helmet", color: "#8a2be2", background: '#8A34FF33' },
        { name: "Boots", color: "#ffd700", background: '#FFEB3433'},
        { name: "Life Support", color: "#8a2be2", background: '#8A34FF33' },
        { name: "Thermal Management", color: "#ffd700", background: '#FFEB3433'},
        { name: "Power", color: "#8a2be2", background: '#8A34FF33' },
    ];

    const [progresses, setProgresses] = useState(Array(items.length).fill(0)); // Track progress for each item
    const timelineBarRefs = useRef([]); // Array of refs for each timeline bar

    const handleDragStart = (index, event) => {
        event.preventDefault();
        document.addEventListener('mousemove', (e) => handleDrag(index, e));
        document.addEventListener('mouseup', handleDragEnd);
    };

    const handleDrag = (index, event) => {
        const timelineBar = timelineBarRefs.current[index];
        if (!timelineBar) return;

        const barRect = timelineBar.getBoundingClientRect();
        const newProgress = Math.min(
            Math.max(0, Math.round(((event.clientX - barRect.left) / barRect.width) * 10)),
            10
        );

        setProgresses((prev) => {
            const updatedProgresses = [...prev];
            updatedProgresses[index] = newProgress;
            return updatedProgresses;
        });
    };

    const handleDragEnd = () => {
        document.removeEventListener('mousemove', handleDrag);
        document.removeEventListener('mouseup', handleDragEnd);
    };
    return (
        <div className="dashboard-container">
            {/* Top Section with Four Columns */}
            {/* Top Section with Timeline View and Indicators */}
            <div className="top-section">
                <div className="column toggle-column">
                    <label className="toggle-label">
                        <span>Timeline View</span>
                        <input type="checkbox" className="toggle-switch" />
                        <span className="toggle-slider"></span>
                    </label>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="checkpoint-dot"></span> Checkpoint
                    </div>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="current-status-diamond"></span> Current Status
                    </div>
                </div>
                <div className="column indicator-column">
                    <div className="indicator">
                        <span className="target-goal-diamond yellow"></span>
                        <span className="target-goal-diamond red"></span> Target Goal
                    </div>
                </div>
            </div>

            {/* Timeline Bars */}
            <div className="timeline-section">
                {items.map((item, index) => (
                    <div className="timeline-row" key={index}>
                        <div className="timeline-label" style={{ backgroundColor: item.background, borderColor: item.color, color: "#fff" }}>
                            {item.name} ({index + 1})
                        </div>
                        <div
                            className="timeline-bar"
                            ref={(el) => (timelineBarRefs.current[index] = el)}
                            style={{ background: `linear-gradient(to right, ${item.color}, #888)` }}
                        >
                            {[...Array(10)].map((_, i) => (
                                <span
                                    key={i}
                                    className={`checkpoint ${i <= progresses[index] ? 'active' : ''} ${i === progresses[index] ? 'current' : ''}`}
                                    style={{
                                        backgroundColor: i <= progresses[index] ? item.color : "#888",
                                    }}
                                ></span>
                            ))}
                            <span
                                className="draggable-handle"
                                style={{ left: `${(progresses[index] / 9) * 99}%`, backgroundColor: item.color }}
                                onMouseDown={(event) => handleDragStart(index, event)}
                            >
                                <small className="handle-text">{item.name}</small>
                            </span>

                        </div>
                    </div>
                ))}
            </div>


        </div>
    );
}
