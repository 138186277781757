import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './ThermalInsulationLayering.css';

export default function ThermalInsulationLayering() {
  return (
    <Row>
      <Col md={12} className="thermal-insulation-layering-container">
      <div className="steps-container">
        <div className="step">
          <div className="step-title">Material Preparation</div>
          <div className="step-content">
            Collect and prepare Multi-Layer Insulation, MLI sheets and aerogel panels.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Cutting</div>
          <div className="step-content">
            Cut MLI sheets and aerogel panels to precise dimensions as per design specifications.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Layer Stacking</div>
          <div className="step-content">
            Stack the layers of MLI and aerogel in the correct sequence to form the insulation structure.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Stitching or Bonding</div>
          <div className="step-content">
            Securely stitch or bond the layers together to ensure they remain in place.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Edge Sealing</div>
          <div className="step-content">
            Seal the edges of the stacked layers to prevent fraying and ensure integrity.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Attachment Points</div>
          <div className="step-content">
            Integrate attachment points for securing the insulation to the suit’s inner and outer layers.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Quality Inspection</div>
          <div className="step-content">
            Inspect the layered insulation for uniformity, coverage, and any defects.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Integration with Outer Shell</div>
          <div className="step-content">
            Attach the layered insulation to the outer shell fabric of the suit.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Thermal Testing</div>
          <div className="step-content">
            Conduct thermal performance tests to ensure the insulation meets required standards.
          </div>
        </div>
        <div className="step">
          <div className="step-title">Final Adjustments</div>
          <div className="step-content">
            Make any necessary adjustments and perform a final inspection to ensure quality and performance.
          </div>
        </div>
      </div>
    </Col>
    </Row>
    
  );
}
