import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import './Calendar.css'; // Custom styles for this component

const Calendar = () => {
  const [events, setEvents] = useState([]);

  // Mock real-time events, replace with actual API calls for real-time data
  useEffect(() => {
    const fetchEvents = async () => {
      const eventList = [
        {
          title: "New Year's Day",
          start: '2024-01-01',
          end: '2024-01-01',
        },
        {
          title: 'Independence Day',
          start: '2024-07-04',
          end: '2024-07-04',
        },
        {
          title: 'Meeting with team',
          start: '2024-01-01',
          end: '2024-01-01',
        },
      ];

      setEvents(eventList);
    };
    fetchEvents();
  }, []);

  return (
    <div className="calendar-container">
      <h3>Your Calendar</h3>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        height="80vh"
        eventContent={(eventInfo) => (
          <div className="event-content" style={{ color: '#fff' }}>
            {eventInfo.event.title}
          </div>
        )}
        dayCellContent={(dayCellContent) => {
          const today = new Date();
          const isToday = today.toISOString().slice(0, 10) === dayCellContent.date.toISOString().slice(0, 10);
          return (
            <span style={{ color: isToday ? '#ff6347' : '#fff' }}>
              {dayCellContent.dayNumberText}
            </span>
          );
        }}
      />
    </div>
  );
};

export default Calendar;
