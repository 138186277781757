
import React, { useState } from 'react';
import { Dropdown, ButtonGroup, Button, Modal } from 'react-bootstrap';
import { Container, Row, Col } from "react-bootstrap";


import './QualityTestingOption.css';
import { Background } from 'react-flow-renderer';
import QualityTestingOptionsMenu from './QualityTestingOptionsMenu/QualityTestingOptionsMenu';
import OptionsPanel from './QualityTestingData';
import XEvasModal from '../../Design/DesignStages/DesignStagesModals/XEvasModal/XEvasModal';
import DesignProgressBar from '../../../common/DesignProgressBar/DesignProgressBar';
import InitialInspiration from '../../Design/DesignStages/Stages/InitialInspiration/InitialInspiration';
import DesignStageDropdown from '../../Design/DesignStages/DesignStageDropdown/DesignStageDropdown';
import AS9100DModal from '../../Design/DesignStages/DesignStagesModals/AS9100DModal/AS9100DModal';
import ASTMModal from '../../Design/DesignStages/DesignStagesModals/ASTMModal/ASTMModal';


export default function QualityTestingOption() {


  const [activeComponent, setActiveComponent] = useState('optionsPanel'); // Set default to 'optionsPanel'
  const [showModal, setShowModal] = useState(null); // Fix: Define showModal state
  const [modalShow, setModalShow] = useState({ xevas: false, as9100d: false, astm: false });

  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  const handleInfoIconClick = (modalType) => {
    setShowModal(modalType);
  };

  const closeModal = () => {
    setShowModal(null); // Close modal
  };

  const handleModalClose = () => setModalShow({ xevas: false, as9100d: false, astm: false });
  
  const handleModalShow = (modal) => {
    setModalShow(prevState => ({ ...prevState, [modal]: true }));
  };

  

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);  // Update state based on dropdown open/close
  };


  return (
    <div className="quality-testing-option-container">
      {/* Top Section with Dropdown and Menu */}
      <Row>
        <Col md={2}>
        <div className="quality-testing-option-top-bar  bg-black">
        <Dropdown as={ButtonGroup} className="option-test-dropdown  btn-rad"  onToggle={handleToggle}>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className='btn-curve'>
          Select Test &nbsp;&nbsp;&nbsp;
          <i className={`fa ${isOpen ? 'fa-angle-down' : 'fa-angle-right'}`} aria-hidden="true"></i>
          </Dropdown.Toggle>

        <Dropdown.Menu className="custom-dropdown bg-black">
            <Dropdown.Item onClick={() => handleModalShow('xevas')}>
              X-EVAS <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleModalShow('as9100d')}>
              AS9100D <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleModalShow('astm')}>
              ASTM <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              MIL STD <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Cybersecurity <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Medtech <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Radiation <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Ballistics <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Crash Test <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Export Controls <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Environmental Test <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Concept Exploration <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
            <Dropdown.Item href="#/action-1">
              Electromagnetic Test <i className="fas fa-info-circle"></i>
            </Dropdown.Item>
      </Dropdown.Menu>
        </Dropdown>
      </div>
        </Col>
        <Col md={6}>
        <div className="content-section">
        <div className='row'>
          <div className='col-md-12'>
            <div className="image-col-style">
              {/* Placeholder for Image */}
              <img
                src={require('../../../../assets/admin/quality-testing/terra-studio-alpha2.png')}
                alt="Test Object"
                className="image-style"
              />
               <div className="quality-eclipse-style"></div>
            </div>
          </div>
          <div className='col-md-12'>
            <div className="bottom-section">
              <div className="range-container">
                <span className="time-label start-time">0% (0.0mb)</span>
                <input type="range" min="0" max="100" className="range-slider" />
                <span className="time-label end-time">100% (98.19mb)</span>
              </div>
              <div className="play-controls">
                <div className="control-item">
                  <button className="btn btn-dark control-button">Large ▾</button>
                  <label className="control-label">Quality</label>
                </div>
                <div className="control-item">
                  <button className="btn btn-dark control-button">⏹</button>
                  <label className="control-label">Stop</label>
                </div>
                <div className="control-item">
                  <button className="btn btn-dark control-button">▶</button>
                  <label className="control-label">Start</label>
                </div>
                <div className="control-item">
                  <button className="btn btn-dark control-button">⏸</button>
                  <label className="control-label">Pause</label>
                </div>
                <div className="control-item">
                  <span className="control-button">1.00x</span>
                  <label className="control-label">Speed</label>
                </div>
              </div>

            </div>
          </div>
        </div>
  

         {/* Modals */}
            <Modal show={showModal === 'xevas'} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>X-EVAS Test</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DesignProgressBar />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal === 'as9100d'} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>AS9100D Test</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <InitialInspiration />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal === 'astm'} onHide={closeModal}>
              <Modal.Header closeButton>
                <Modal.Title>ASTM Test</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DesignStageDropdown />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
     
      </div>
        </Col>
        <Col md={4}>
        <Row>
          <Col md={2}>
          <div className='btn-switch'> 
            <button 
              className={`btn-icon ${activeComponent === 'optionsPanel' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('optionsPanel')}
            >
              <i className="fas fa-bars"></i>
            </button>
    
            <button 
              className={`btn-icon ${activeComponent === 'qualityTestingOptionsMenu' ? 'active' : ''}`} 
              onClick={() => handleButtonClick('qualityTestingOptionsMenu')}
            >
              <i className="fas fa-chart-line"></i>
            </button>
       </div>
          </Col>
          <Col md={1}>
          <div className="options-col background">
          <div className="quality-testing-option-sidebar">
            {/* Conditionally render OptionsPanel or QualityTestingOptionsMenu */}
            {activeComponent === 'optionsPanel' && <OptionsPanel />}
            {activeComponent === 'qualityTestingOptionsMenu' && <QualityTestingOptionsMenu />}
          </div>
       </div>
          </Col>
        </Row>
        </Col>
      </Row>

      {/* Main Content Area */}
      

      {/* Bottom Section with Range and Play Controls */}
      <XEvasModal show={modalShow.xevas} handleClose={handleModalClose} />
      <AS9100DModal show={modalShow.as9100d} handleClose={handleModalClose} />
      <ASTMModal show={modalShow.astm} handleClose={handleModalClose} />
    </div>
  );
}
