import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import './AS9100DModal.css';

export default function AS9100DModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="as9100d-modal">
      <Modal.Header closeButton>
        <Modal.Title>AS9100D</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={require('../../../../../../assets/admin/design/modal-images/AS9100DImage.png')} alt="AS9100D Logo" className="standard-logo" /> {/* Replace with the actual path to the AS9100D logo */}
        <p>The AS9100D standard is a widely recognized quality management system (QMS) tailored specifically for the aerospace industry. It builds on the ISO 9001 standards, incorporating additional requirements to ensure the safe development, production, and distribution of aerospace parts and products.</p>
        <hr />
        <h5>Area:</h5>
        <p>Aerospace</p>
        <h5>Key Features and Requirements</h5>
        <ul>
          <li>Quality Management System (QMS): Organizations must establish, document, and maintain a QMS that meets the standard's requirements. This includes creating policies, procedures, and records that demonstrate the organization’s commitment to quality and continual improvement.</li>
          <li>Leadership and Commitment: Top management must demonstrate leadership and commitment to the QMS, ensuring that quality objectives are aligned with the organization’s strategic direction.</li>
          <li>Risk Management: The standard emphasizes a proactive approach to identifying and managing risks that could affect the quality of products and services.</li>
          <li>Product Realization: This involves planning and controlling the processes needed to ensure that products meet customer requirements, from design and development through production and delivery.</li>
          <li>Monitoring and Measurement: Organizations must establish processes to monitor and measure the performance of the QMS, including internal audits and management reviews.</li>
        </ul>
        <h5>Audit Process</h5>
        <ul>
          <li>Manufacturing on Demand</li>
          <li><a href="https://www.dicksondata.com" target="_blank" rel="noopener noreferrer">Dickson Data</a></li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
