import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { engineerData } from '../../../data/engineerData'; // Assuming engineerData is similar to designData

import UploadModal from '../../common/UploadModal/UploadModal';
import ViewModal from '../../common/ViewModal/ViewModal';
import './Engineering.css';

export default function Engineering() {
  const [expandedRows, setExpandedRows] = useState({});

  const navigate = useNavigate();


  const [showUploadModal, setShowUploadModal] = useState(false); // To manage modal state
  const [showViewModal, setShowViewModal] = useState(false);

  const handleShowModal = () => setShowUploadModal(true);
  const handleCloseModal = () => setShowUploadModal(false);


  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);

  const handleShowViewModal = () => setShowViewModal(true);
  const handleCloseViewModal = () => setShowViewModal(false);


  const handleItemClick = (item) => {
    if (item.name === 'Bottom Layer Heat Test') {
      navigate('/admin/engineering-screen'); // Navigate to the specified route
    }
  };

  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };


  const renderRows = (items, level = 0) => {
    return items.map(item => (
      <React.Fragment key={item.id}>
        <tr className={`level-${level}`}>
          <td>
            <input type="checkbox" className="engineering-custom-checkbox" />
          </td>
          <td onClick={() => toggleRow(item.id)} style={{ cursor: 'pointer' }}>
            {expandedRows[item.id] ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>} {item.name}
          </td>
          <td>{item.lastUpdated}</td>
          <td>{item.program}</td>
          <td>{item.fileType}</td>
          <td>
            <div className="creators">
              {item.creators && item.creators.length > 0 ? (
                item.creators.map((creator, index) => (
                  <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                    <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                    {creator.name}
                  </span>
                ))
              ) : (
                <span>No creators assigned</span>
              )}
            </div>
          </td>
        </tr>
        {expandedRows[item.id] && item.subItems && renderRows(item.subItems, level + 1)}
        {expandedRows[item.id] && item.details && item.details.map(detail => (
          <tr key={detail.id} className={`level-${level + 1}`} onClick={() => handleItemClick(detail)}>
            <td></td>
            <td>{detail.name}</td>
            <td>{detail.lastUpdated}</td>
            <td>{detail.program}</td>
            <td>{detail.fileType}</td>
            <td>
              <div className="creators">
                {detail.creators && detail.creators.length > 0 ? (
                  detail.creators.map((creator, index) => (
                    <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                      <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                      {creator.name}
                    </span>
                  ))
                ) : (
                  <span>No creators assigned</span>
                )}
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };





  return (
    <Container fluid className="engineering-container">
      {/* Previous Activity and Digital Product Documentation Section */}
      <Row className="top-section">
        <Col md={6}>
          <div className="section-header">
            <h4>Previous Activity</h4>
            <div className="menu-icon">
              <Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
            </div>
          </div>
          <div className="engineering-divider"></div>
          <div className="previous-activity">
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/VisorFlexTest.png')} className="activity-image" alt="Visor Flex Test" />
              <div className="image-overlay">
                <div className="image-text">Visor Flex Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/FabricTest.png')} className="activity-image" alt="Fabric Test" />
              <div className="image-overlay">
                <div className="image-text">Fabric Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/StrengthTest.png')} className="activity-image" alt="Strength Test" />
              <div className="image-overlay">
                <div className="image-text">Strength Test</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/StructuralAnalysis.png')} className="activity-image" alt="Structural Analysis" />
              <div className="image-overlay">
                <div className="image-text">Structural Analysis</div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="section-header">
            <h4>Digital Product Documentation</h4>
            <div className="menu-icon"><Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button></div>
          </div>
          <div className="engineering-divider"></div>
          <div className="digital-docs">
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/Assets.png')} className="doc-image" alt="Assets" />
              <div className="image-overlay">
                <div className="image-text">Assets</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/DesignDocumentation.png')} className="doc-image" alt="Design Documentation" />
              <div className="image-overlay">
                <div className="image-text">Design Documentation</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/TechnicalDataSheets.png')} className="doc-image" alt="Technical Data Sheet" />
              <div className="image-overlay">
                <div className="image-text">Technical Data Sheet</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/InstallationGuides.png')} className="doc-image" alt="Installation Guides" />
              <div className="image-overlay">
                <div className="image-text">Installation Guides</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/SpecificationsStandards.png')} className="doc-image" alt="Specifications and Standards" />
              <div className="image-overlay">
                <div className="image-text">Specifications and Standards</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/engineering/ProcessDocumentation.png')} className="doc-image" alt="Process Documentation" />
              <div className="image-overlay">
                <div className="image-text">Process Documentation</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Assets Section */}
      <Row className="assets-section mt-5">
        <Col md={12}>
          <div className="section-header">
            <h4>Assets</h4>
            <div className="table-actions mb-2">
              <Button variant="light rounded-pill" onClick={handleShowModal}>Upload</Button>
              <Button variant="light rounded-pill" onClick={handleShowViewModal}>View</Button>
              <Button variant="btn btn-outline-light rounded-pill w-25">Edit</Button>
              <Form.Control type="text" placeholder="Search" className="assets-search" />
              <Button variant="btn btn-outline-light rounded-pill" style={{ width: '80px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
              <Button variant="btn btn-outline-light rounded-pill" style={{ width: '80px' }}><img src={require('../../../assets/admin/requirement/downloadImage.png')} /></Button>
            </div>
          </div>
          <div className="assets-divider"></div>
          <div className='assets-table-data'>
          <Table responsive className="assets-table  ">
            <thead>
              <tr>
                <th></th>
                <th>Assets</th>
                <th>Last Updated</th>
                <th>Program</th>
                <th>File Type</th>
                <th>Creators</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(engineerData)}
            </tbody>
          </Table>
          </div>

          <UploadModal show={showUploadModal} handleClose={handleCloseModal} />
          <ViewModal show={showViewModal} handleClose={handleCloseViewModal} />
        </Col>
      </Row>
    </Container>
  );
}
