import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Form, Table, Modal } from 'react-bootstrap';
import './SubDivision.css';
import { API_BASE_URL } from '../../../../config/config';

export default function SubDivision() {
  const [subdivisions, setSubDivisions] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    subdivision_name: '',
    description: '',
    status: 'active',
    division_id: '',
  });
  const [selectedSubDivision, setSelectedSubDivision] = useState(null);

  const SUBDIVISION_API_URL = `${API_BASE_URL}/requirements/subdivisions`;
  const DIVISION_API_URL = `${API_BASE_URL}/requirements/divisions`;

  // Fetch subdivisions
  const fetchSubDivisions = async () => {
    try {
      const response = await axios.get(SUBDIVISION_API_URL);
      setSubDivisions(response.data);
    } catch (error) {
      console.error('Error fetching subdivisions:', error);
    }
  };

  // Fetch divisions for the dropdown
  const fetchDivisions = async () => {
    try {
      const response = await axios.get(DIVISION_API_URL);
      setDivisions(response.data);
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  useEffect(() => {
    fetchSubDivisions();
    fetchDivisions();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle adding or updating a subdivision
  const handleSaveSubDivision = async () => {
    try {
      if (selectedSubDivision) {
        // Update existing subdivision
        await axios.put(`${SUBDIVISION_API_URL}/${selectedSubDivision._id}`, formData);
      } else {
        // Create new subdivision
        await axios.post(SUBDIVISION_API_URL, formData);
      }
      fetchSubDivisions();
      setShowModal(false);
      setSelectedSubDivision(null);
      setFormData({
        subdivision_name: '',
        description: '',
        status: 'active',
        division_id: '',
      });
    } catch (error) {
      console.error('Error saving subdivision:', error);
    }
  };

  // Handle editing a subdivision
  const handleEditSubDivision = (subdivision) => {
    setSelectedSubDivision(subdivision);
    setFormData({
      subdivision_name: subdivision.subdivision_name,
      description: subdivision.description,
      status: subdivision.status,
      division_id: subdivision.division_id._id, // Use the ID of the division for editing
    });
    setShowModal(true);
  };

  // Handle deleting a subdivision
  const handleDeleteSubDivision = async (id) => {
    try {
      await axios.delete(`${SUBDIVISION_API_URL}/${id}`);
      fetchSubDivisions();
    } catch (error) {
      console.error('Error deleting subdivision:', error);
    }
  };

  return (
    <Container fluid className="subdivision-container">
      <Row className="top-section">
        <Col md={6}>
          <h4>SubDivisions</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center subdivision-buttons">
          <Button variant="light" onClick={() => setShowModal(true)}>
            Create New
          </Button>
        </Col>
      </Row>
      <div className="subdivision-divider mb-4"></div>

      <Table responsive className="subdivision-table">
        <thead>
          <tr>
            <th>Subdivision Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Division</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subdivisions.map((subdivision) => (
            <tr key={subdivision._id}>
              <td>{subdivision.subdivision_name}</td>
              <td>{subdivision.description}</td>
              <td>{subdivision.status}</td>
              <td>{subdivision.division_id?.division_name || subdivision.division_id}</td> {/* Display division_name */}
              <td>
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => handleEditSubDivision(subdivision)}
                  className="m-1"
                >
                  Edit
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeleteSubDivision(subdivision._id)}
                  className="m-1"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Create/Edit SubDivision */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedSubDivision ? 'Edit SubDivision' : 'Create SubDivision'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
          <Form.Group controlId="divisionId" className="mt-3">
              <Form.Label>Division</Form.Label>
              <Form.Control
                as="select"
                name="division_id"
                value={formData.division_id}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Division</option>
                {divisions.map((division) => (
                  <option key={division._id} value={division._id}>
                    {division.division_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="subdivisionName">
              <Form.Label>Subdivision Name</Form.Label>
              <Form.Control
                type="text"
                name="subdivision_name"
                value={formData.subdivision_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status" className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveSubDivision}>
            {selectedSubDivision ? 'Update' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
