export const designData = [
    {
      id: '1',
      name: 'Helmet',
      lastUpdated: '05.13.2023',
      program: 'SolidWorks, Blender',
      fileType: 'obj, stl',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' },
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
      ],
      subItems: [
        {
          id: '1-1',
          name: 'Helmet Shell',
          lastUpdated: '05.13.2023',
          program: 'SolidWorks',
          fileType: 'obj',
          creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }],
          subItems: [
            {
              id: '1-1-1',
              name: 'Visor',
              details: [
                { 
                  id: '1-1-1-1', 
                  name: 'Peripheral Vision Enhancement', 
                  lastUpdated: '05.13.2023', 
                  program: 'SolidWorks', 
                  fileType: 'obj', 
                  creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }]
                },
                { 
                  id: '1-1-1-2', 
                  name: 'Visor Piece', 
                  lastUpdated: '01.10.2022', 
                  program: 'Blender', 
                  fileType: 'stl', 
                  creators: [{ name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      id: '2',
      name: 'Boots',
      lastUpdated: '05.18.2023',
      program: 'AutoCAD',
      fileType: 'obj, stl',
      creators: [
        { name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#0094FF' }
      ]
    },
    {
      id: '3',
      name: 'Chest Piece',
      lastUpdated: '01.10.2022',
      program: 'AutoCAD, Blender',
      fileType: 'obj, stl',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#F2DA05' }
      ]
    },
    {
      id: '4',
      name: 'Helmet Lighting Design',
      lastUpdated: '01.10.2022',
      program: 'Blender',
      fileType: 'obj',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' },
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
      ]
    },
    {
      id: '5',
      name: 'Gauntlet Design',
      lastUpdated: '01.10.2022',
      program: 'Fusion 360, Inventor',
      fileType: 'obj, stl',
      creators: [
        { name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#F2DA05' }
      ]
    },
    {
      id: '6',
      name: 'Glove Design',
      lastUpdated: '01.10.2022',
      program: 'Blender',
      fileType: 'obj',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }
      ]
    },
    {
      id: '7',
      name: 'Interior Lining Design',
      lastUpdated: '01.10.2022',
      program: 'Fusion 360, Blender',
      fileType: 'obj, stl',
      creators: [
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
      ]
    },
    {
      id: '8',
      name: 'Temperature Regulation System',
      lastUpdated: '01.10.2022',
      program: 'AutoCAD',
      fileType: 'obj, stl',
      creators: [
        { name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#F2DA05' }
      ]
    },
    {
      id: '9',
      name: 'Outer Shell Design',
      lastUpdated: '01.10.2022',
      program: 'Blender, SolidWorks',
      fileType: 'obj, stl',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }
      ]
    },
    {
      id: '10',
      name: 'Life Support Interface Design',
      lastUpdated: '01.10.2022',
      program: 'Autodesk Inventor',
      fileType: 'stl',
      creators: [
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
      ]
    },
    {
      id: '11',
      name: 'Harness Design',
      lastUpdated: '01.10.2022',
      program: 'Civil 3D, SolidWorks',
      fileType: 'obj, stl',
      creators: [
        { name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#F2DA05' }
      ]
    },
    {
      id: '12',
      name: 'Internal Storage Design',
      lastUpdated: '01.10.2022',
      program: 'Blender, Fusion 360',
      fileType: 'obj, stl',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }
      ]
    },
    {
      id: '13',
      name: 'Boot Design',
      lastUpdated: '01.10.2022',
      program: 'Blender',
      fileType: 'obj, stl',
      creators: [
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
      ]
    },
    {
      id: '14',
      name: 'Interior Lining Design',
      lastUpdated: '01.10.2022',
      program: 'Fusion 360, Blender',
      fileType: 'obj, stl',
      creators: [
        { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#F2DA05' }
      ]
    },
    {
      id: '15',
      name: 'Leg Design',
      lastUpdated: '01.10.2022',
      program: 'Civil 3D, AutoCAD',
      fileType: 'obj, stl',
      creators: [
        { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }
      ]
    },
    {
      id: '16',
      name: 'Knee Joint Design',
      lastUpdated: '01.10.2022',
      program: 'Fusion 360',
      fileType: 'stl',
      creators: [
        { name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#0094FF' }
      ]
    }
  ];
  