import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../../../config/config';
import { Container, Row, Col, Button, Form, Table, Modal } from 'react-bootstrap';
import './Division.css';

export default function Division() {
  const [divisions, setDivisions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    division_name: '',
    description: '',
    status: 'active',
  });
  const [selectedDivision, setSelectedDivision] = useState(null);

  const API_URL = `${API_BASE_URL}/requirements/divisions`;

  // Fetch divisions
  const fetchDivisions = async () => {
    try {
      const response = await axios.get(API_URL);
      setDivisions(response.data);
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle adding or updating a division
  const handleSaveDivision = async () => {
    try {
      if (selectedDivision) {
        // Update existing division
        await axios.put(`${API_URL}/${selectedDivision._id}`, formData);
      } else {
        // Create new division
        await axios.post(API_URL, formData);
      }
      fetchDivisions();
      setShowModal(false);
      setSelectedDivision(null);
      setFormData({
        division_name: '',
        description: '',
        status: 'active',
      });
    } catch (error) {
      console.error('Error saving division:', error);
    }
  };

  // Handle editing a division
  const handleEditDivision = (division) => {
    setSelectedDivision(division);
    setFormData({
      division_name: division.division_name,
      description: division.description,
      status: division.status,
    });
    setShowModal(true);
  };

  // Handle deleting a division
  const handleDeleteDivision = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchDivisions();
    } catch (error) {
      console.error('Error deleting division:', error);
    }
  };

  return (
    <Container fluid className="division-container">
      <Row className="top-section">
        <Col md={6}>
          <h4>Divisions</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center division-buttons">
          <Button variant="light" onClick={() => setShowModal(true)}>
            Create New
          </Button>
        </Col>
      </Row>
      <div className="division-divider mb-4"></div>

      <Table responsive className="division-table">
        <thead>
          <tr>
            <th>Division Name</th>
            <th>Description</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {divisions.map((division) => (
            <tr key={division._id}>
              <td>{division.division_name}</td>
              <td>{division.description}</td>
              <td>{division.status}</td>
              <td>
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => handleEditDivision(division)}
                  className="m-1"
                >
                  Edit
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeleteDivision(division._id)}
                  className="m-1"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Create/Edit Division */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedDivision ? 'Edit Division' : 'Create Division'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="divisionName">
              <Form.Label>Division Name</Form.Label>
              <Form.Control
                type="text"
                name="division_name"
                value={formData.division_name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="description" className="mt-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="status" className="mt-3">
              <Form.Label>Status</Form.Label>
              <Form.Control
                as="select"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveDivision}>
            {selectedDivision ? 'Update' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
