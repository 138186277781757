import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import './TooltipStyle.css';

const BreakdownTooltip = ({ data }) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="tooltip-body">
          <div className="tooltip-header">
            <span>Assigned to:</span>
            <div className="tooltip-avatar">
              {data.avatar && <img src={data.avatar} alt="user-avatar" />}
              <span>{data.assignedTo}</span>
            </div>
          </div>
          <div className="tooltip-row">
            <span>Last Updated: <span className={data.lastUpdated === 'Not Started' ? 'red-text' : ''}>{data.lastUpdated}</span></span>
          </div>
          <div className="tooltip-row">
            <span>Goal: {data.goal || 'Unknown'}</span>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="top" overlay={popover} rootClose>
      <span className={`breakdown-tag ${data.lastUpdated === 'Not Started' ? 'red' : 'yellow'}`}>
        {data.name}
      </span>
    </OverlayTrigger>
  );
};

export default BreakdownTooltip;
