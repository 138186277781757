import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Schedule.css'; // Custom styles

const localizer = momentLocalizer(moment);

const Schedule = () => {
  const events = [
    {
      title: 'Meeting',
      start: new Date(2024, 7, 4, 15, 30), // August 4, 2024, 3:30 PM
      end: new Date(2024, 7, 4, 16, 30), // August 4, 2024, 4:30 PM
    },
    {
      title: 'Testing',
      start: new Date(2024, 7, 7, 11, 0), // August 7, 2024, 11:00 AM
      end: new Date(2024, 7, 7, 12, 0), // August 7, 2024, 12:00 PM
    },
    {
      title: 'Testing',
      start: new Date(2024, 7, 13, 13, 20), // August 13, 2024, 1:20 PM
      end: new Date(2024, 7, 13, 14, 20), // August 13, 2024, 2:20 PM
    }
  ];

  return (
    <div className="schedule-container">
      <h5>Schedule</h5>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        defaultDate={new Date(2024, 7, 1)}
        style={{ height: 500 }}
        views={['month']}
        components={{
          month: {
            dateHeader: ({ label }) => (
              <span>{label}</span>
            )
          }
        }}
      />
    </div>
  );
};

export default Schedule;
