import React, {useState} from 'react';
import './Order.css'; // Ensure this file includes the necessary styles
import { useNavigate, useLocation } from 'react-router-dom';
import { orders } from '../../../../data/orderData';
import { Table, Button, Form } from 'react-bootstrap';

export default function Order() {
  
  const navigate = useNavigate(); // For navigation

  const [expandedRows, setExpandedRows] = useState({}); // Use for any expandable rows if needed

  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const handleRouteNavigation = (value) => {

    if (value === 'bill') {
      navigate('/admin/production-bill-of-materials')
    } else if (value === 'order') {
      navigate('/admin/production-order');
    } else {
      navigate('/admin/production-routing');
    }

  }

  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  const renderRows = (items) => {
    return items.map((item, index) => (
      <tr key={index}>
        <td><Form.Check type="checkbox" /></td>
        <td>{item.date} <br /> {item.time}</td>
        <td><i>{item.notes}</i></td>
        <td>{item.amount}</td>
        <td>{item.startDate}</td>
        <td>{item.endDate}</td>
        <td className={`status ${item.status.toLowerCase()}`}>{item.status}</td>
        <td>
          <div className={`profile-border ${item.status.toLowerCase()}`}>
            <img src={item.avatar} alt={item.creator} className="avatar" />
            {item.creator}
          </div>
        </td>
      </tr>
    ));
  };



  return (
    <div className="order-container">
      {/* Breadcrumb */}

      <div className="breadcrumb-order">
        <span className="breadcrumb-item-order" onClick={() => handleRouteNavigation('bill')}>Bill of Materials</span>
        <span className="breadcrumb-arrow-order"><i class="routing-arrow-icon"></i></span>
        <span className="breadcrumb-item-order" onClick={() => handleRouteNavigation('routing')}>Routings</span>
        <span className="breadcrumb-arrow-order"><i class="routing-arrow-icon"></i></span>
        <span className={`breadcrumb-item-order ${isActive('/admin/production-order') ? 'active' : ''}`} onClick={() => handleRouteNavigation('order')}>Production Orders</span>
      </div>


      {/* Orders Table */}
      <div className='row mt-5'>
        <div className='col-md-6 mt-3'>
          <h4 className="section-title">Production Orders</h4>

        </div>
        <div className='col-md-6'>
          <div className="order-actions">
            <Button variant="light">Upload</Button>
            <Button variant="light">Edit</Button>
            <Form.Control type="text" placeholder="Search" className="order-search" />
            <Button variant="light">Print</Button>
          </div>
        </div>
      </div>



      <Table className="order-table" responsive>
        <thead>
          <tr>
            <th></th>
            <th>Creation Date</th>
            <th>Notes</th>
            <th>Cost Amount</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>Creator</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(orders)} {/* Render order data from the imported file */}
        </tbody>
      </Table>
    </div>
  );
}
