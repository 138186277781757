import React, { useState, useRef, useEffect } from 'react';
import Tree from 'react-d3-tree';
import BreakdownTooltip from '../BreakdownTooltip/BreakdownTooltip';
import { useNavigate } from 'react-router-dom';
import { Dropdown, InputGroup, FormControl, Modal, Button } from 'react-bootstrap';
import './SafetyRequirement.css';

const data = {
  name: 'Safety Requirements',
  attributes: {
    status: 'In Progress',
    date: '07.02.25',
    bgColor: '#1E1E1E',
    textColor: '#FFF',
    pathColor: 'yellow',
  },
  children: [
    {
      name: 'Pressure Regulation',
      attributes: {
        status: 'In Progress',
        date: '07.02.25',
        bgColor: '#2C3E50',
        textColor: '#ECF0F1',
        pathColor: 'red',
      },
      children: [
        {
          name: 'Suit Protection',
          attributes: {
            status: 'In Progress',
            date: '08.07.23',
            bgColor: '#34495E',
            textColor: '#BDC3C7',
            pathColor: 'green',
          },
          children: [
            {
              name: 'Pressure Relief',
              attributes: {
                status: 'Completed',
                date: '06.18.23',
                bgColor: '#27AE60',
                textColor: '#FFF',
                pathColor: 'blue',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Thermal Control',
      attributes: {
        status: 'In Progress',
        date: '07.02.25',
        bgColor: '#8E44AD',
        textColor: '#FFF',
        pathColor: 'purple',
      },
      children: [
        {
          name: 'Insulation Materials',
          attributes: {
            status: 'In Progress',
            date: '08.07.23',
            bgColor: '#16A085',
            textColor: '#FFF',
            pathColor: 'blue',
          },
          children: [
            {
              name: 'Temperature Regulation',
              attributes: {
                status: 'In Progress',
                date: '08.07.23',
                bgColor: '#2980B9',
                textColor: '#FFF',
                pathColor: 'yellow',
              },
            },
            {
              name: 'Test Results',
              attributes: {
                status: 'Not Started',
                date: '08.07.23',
                bgColor: '#C0392B',
                textColor: '#FFF',
                pathColor: 'red',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Radiation Protection',
      attributes: {
        status: 'In Progress',
        date: '07.02.25',
        bgColor: '#F39C12',
        textColor: '#FFF',
        pathColor: 'yellow',
      },
      children: [
        {
          name: 'Radiation Dose Limits',
          attributes: {
            status: 'In Progress',
            date: '08.07.23',
            bgColor: '#D35400',
            textColor: '#FFF',
            pathColor: 'red',
          },
        },
        {
          name: 'Shielding Materials',
          attributes: {
            status: 'Completed',
            date: '06.18.23',
            bgColor: '#2ECC71',
            textColor: '#FFF',
            pathColor: 'green',
          },
        },
      ],
    },
  ],
};


const assignedUsersData = [
  {
    name: 'Ashley K',
    email: 'AshleyK@gmail.com',
    title: 'Aerospace Engineer',
    image: require('../../../../assets/admin/table-images/image1.png'),
    description: 'I am an aerospace engineer specializing in the design and development of advanced aircraft and spacecraft systems. My expertise includes aerodynamics, propulsion, and systems integration.',
  },
  {
    name: 'Michelle P',
    email: 'MichelleP@gmail.com',
    title: 'Thermal Engineer',
    image: require('../../../../assets/admin/table-images/image2.png'),
    description: 'As a thermal engineer, I work on advanced thermal control systems for spacecraft and other aerospace vehicles, ensuring reliable operation in extreme environments.',
  },
  {
    name: 'Alex L',
    email: 'AlexL@gmail.com',
    title: 'Mechanical Engineer',
    image: require('../../../../assets/admin/table-images/image3.png'),
    description: 'A mechanical engineer focused on the development of spacecraft components, including structural design, materials, and testing for maximum durability and performance.',
  }
];


const breakdownData = [
  {
    name: 'Shielding Materials',
    assignedTo: 'Alex L.',
    lastUpdated: 'Completed',
    goal: 'Protection from radiation',
    avatar: require('../../../../assets/admin/table-images/image3.png'),
  },
  {
    name: 'Pressure Relief',
    assignedTo: 'Michelle P.',
    lastUpdated: 'Completed',
    goal: 'Maintain pressure balance',
    avatar: require('../../../../assets/admin/table-images/image2.png'),
  },
  {
    name: 'Suit Protection',
    assignedTo: 'Ashley K.',
    lastUpdated: 'In Progress',
    goal: 'Provide thermal insulation',
    avatar: require('../../../../assets/admin/table-images/image1.png'),
  },
  {
    name: 'Temperature Reg.',
    assignedTo: 'Michelle P.',
    lastUpdated: 'In Progress',
    goal: 'Regulate internal temperature',
    avatar: require('../../../../assets/admin/table-images/image2.png'),
  },
  {
    name: 'Test Results',
    assignedTo: 'Not Assigned',
    lastUpdated: 'Not Started',
    goal: 'Unknown',
    avatar: null,
  },
  {
    name: 'Radiation Dose Limits',
    assignedTo: 'Alex L.',
    lastUpdated: 'Not Started',
    goal: 'Unknown',
    avatar: require('../../../../assets/admin/table-images/image3.png'),
  },
];



export default function SafetyRequirement() {
  const navigate = useNavigate(); // For navigation
  const [modalData, setModalData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const treeContainerRef = useRef(null);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (isOpen) => {
    setIsOpen(isOpen);
  };

  useEffect(() => {
    const dimensions = treeContainerRef.current.getBoundingClientRect();
    setTranslate({
      x: dimensions.width / 2,
      y: dimensions.height / 4,
    });
  }, []);

  const pathClassFunc = (nodeData, orientation) => {
    return nodeData.attributes && nodeData.attributes.pathColor
      ? nodeData.attributes.pathColor
      : 'default-path';
  };

  const handleNodeClick = (nodeDatum) => {
    setModalData(nodeDatum);
  };

  const handleClose = () => {
    setModalData(null);
  };

  const handleUserInfoModalClose = () => {
    setSelectedUser(null)
  }


  const handleUserClick = (user) => {
    setSelectedUser(user); // Pass the selected user data to the modal
  };

  const handleNavigation = () => {
    navigate('/admin/requirements');
  }
  

  return (
    <div className="safety-requirement-page">
      <div className="safety-requirement-top-bar">
        <div className="safety-requirement-top-bar-left">
          <Dropdown onToggle={handleToggle} className="custom-dropdown">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              Requirement
              {/* {isOpen ? '▲' : '▼'} */}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">Safety Requirements</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Communication Systems</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Environmental Control</Dropdown.Item>
              <Dropdown.Item href="#/action-4">Material Requirements</Dropdown.Item>
              <Dropdown.Item href="#/action-5">Mobility and Dexterity</Dropdown.Item>
              <Dropdown.Item href="#/action-6">Life Support Systems</Dropdown.Item>
              <Dropdown.Item href="#/action-7">Emergency Systems</Dropdown.Item>
              <Dropdown.Item href="#/action-8">Ergonomics and Comfort</Dropdown.Item>
              <Dropdown.Item href="#/action-9">Operational Requirements</Dropdown.Item>
              <Dropdown.Item href="#/action-10">Testing and Certification</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

        </div>
        <div className="safety-requirement-top-bar-right safety-requirement-buttons">
          <Button variant="outline-light" className="top-bar-button w-50" onClick={handleNavigation}>List View</Button>
          <InputGroup className="search-bar m-1">
            <FormControl
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <Button variant="outline-light" className="top-bar-button" style={{ width: '30%' }}>Add</Button>
          <Button variant="light" className="top-bar-button" style={{ width: '20%' }}>☰</Button>
        </div>
      </div>
      <div ref={treeContainerRef} className="tree-container">
        <Tree
          data={data}
          translate={translate}
          nodeSize={{ x: 300, y: 180 }}
          renderCustomNodeElement={(rd3tProps) =>
            renderRectSvgNode(rd3tProps, handleNodeClick)
          }
          orientation="vertical"
          pathFunc="straight"
          pathClassFunc={pathClassFunc}
        />

        {modalData && (
          <Modal show={!!modalData} onHide={handleClose} size='lg' centered dialogClassName="safety-requirement-custom-modal">
            <Modal.Header style={{ border: 'none' }} closeButton>
              <Modal.Title>
                <div className="modal-title-container">
                  <div className="modal-title-text">{modalData.name}</div>
                  <div className="modal-title-completion">2/7 completed</div>
                </div>
                <div className='safety-requirement-divider'></div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-content-container">
                <div className="modal-header-section">
                  <span>Deadline: {modalData.attributes.date}</span>
                </div>
                <div className="modal-header-section">
                  <span>Last Updated: 01.28.25</span>
                </div>
                <div className="modal-row">
                  <div className="modal-left-col">
                    <div className="modal-assigned-section">
                      <div className="assigned-users">
                        <span>Assigned to:</span>
                        {assignedUsersData.map((user, index) => (
                          <div key={index} className="assigned-user" onClick={() => handleUserClick(user)}>
                            <img src={user.image} alt="user" className="user-avatar" />
                            <span>{user.name}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="modal-description">
                      Astronaut protection from space hazards, such as extreme temperatures, micrometeoroids, and radiation, maintaining overall mission safety and viability.
                    </div>
                    <div className="modal-scope">
                      <strong>Scope: </strong>
                      Safety requirements must be reliable and confident for the success of the suit. The safety of the user comes first, and is the most crucial for any spacesuit.
                    </div>
                  </div>
                  <div className="modal-vertical-line"></div>
                  <div className="modal-right-col">
                    <div className="modal-breakdown-section">
                      <strong>Breakdown:</strong>
                      <div className="breakdown-tags">
                        {/* <span className="breakdown-tag green">Shielding Materials</span>
                        <span className="breakdown-tag green">Pressure Relief</span>
                        <span className="breakdown-tag yellow">Suit Protection</span>
                        <span className="breakdown-tag yellow">Temperature Reg.</span>
                        <span className="breakdown-tag red">Test Results</span>
                        <span className="breakdown-tag red">Radiation Dose Limits</span> */}
                        <div className="breakdown-tags">
                          {breakdownData.map((item, index) => (
                            <BreakdownTooltip key={index} data={item} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-start' style={{ border: 'none' }}>
            <div className="safety-requirment-team-legend">
                      <span className="team-label">Teams:</span>
                      <div className="team">
                        <span className="circle yellow"></span>
                        <span className="team-name">Electrical Engineers</span>
                      </div>
                      <div className="team">
                        <span className="circle blue"></span>
                        <span className="team-name">Thermal Engineers</span>
                      </div>
                      <div className="team">
                        <span className="circle purple"></span>
                        <span className="team-name">Bio Medical Engineers</span>
                      </div>
                    </div>
              <Button  className='ms-auto' variant="primary" onClick={handleClose}>
                Submit Entry
              </Button>
            </Modal.Footer>
          </Modal>
        )}

      </div>

      {selectedUser && (
        <Modal show={!!selectedUser} onHide={handleUserInfoModalClose} centered className="user-info-modal">
          <Modal.Header style={{ border: 'none' }} closeButton></Modal.Header>
          <Modal.Body>
            <div className="user-info-modal-content">
              <img src={selectedUser.image} alt={selectedUser.name} className="user-avatar-modal" />
              <h3>{selectedUser.name}</h3>
              <p><a href={`mailto:${selectedUser.email}`} className="user-email">{selectedUser.email}</a></p>
              <p className="user-description">{selectedUser.description}</p>
              <input type="text" placeholder="Send a message" className="user-message-input" />
              <div className="modal-icons">
                <i className="fas fa-phone"></i>
                <i className="fas fa-video"></i>
                <i className="fas fa-comment"></i>
              </div>
              <h5 className="user-title">{selectedUser.title}</h5>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

const renderRectSvgNode = ({ nodeDatum }, handleNodeClick) => (
  <g onClick={() => handleNodeClick(nodeDatum)}>
    <rect
      width="260"
      height="80"
      x="-130"
      y="-40"
      fill={nodeDatum.attributes && nodeDatum.attributes.bgColor ? nodeDatum.attributes.bgColor : "#333"}
      rx="15"
    />
    <text fill={nodeDatum.attributes && nodeDatum.attributes.textColor ? nodeDatum.attributes.textColor : "#fff"} x="0" y="-10" textAnchor="middle" fontSize="14">
      {nodeDatum.name}
    </text>
    <text fill={nodeDatum.attributes && nodeDatum.attributes.textColor ? nodeDatum.attributes.textColor : "#fff"} x="0" y="10" textAnchor="middle" fontSize="12">
      {nodeDatum.attributes.status} - {nodeDatum.attributes.date}
    </text>
    {nodeDatum.attributes.icon && (
      <text fill="#FFC107" x="-115" y="-25" textAnchor="middle" fontSize="20">
        {nodeDatum.attributes.icon === 'warning' ? '⚠️' : '❗'}
      </text>
    )}
    {nodeDatum.attributes.avatars && nodeDatum.attributes.avatars.map((avatar, index) => (
      <image
        href={`https://via.placeholder.com/20`}
        x={-130 + index * 25}
        y="20"
        height="20px"
        width="20px"
        clipPath="circle(50% at 50% 50%)"
      />
    ))}
  </g>
);
