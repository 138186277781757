import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import './NestedTable.css';
import CreatorTooltipModal from '../CreatorTooltipModal/CreatorTooltipModal';

const data = [
  {
    id: 1,
    name: 'Helmet',
    date: '05.13.2023',
    programs: 'SolidWorks, Blender',
    creators: [
      { 
        name: 'Michelle Pankratz', 
        avatar: require('../../../assets/admin/table-images/image1.png'),
        email: 'MPankratz@email.com',
        role: 'Civil Engineer',
        bio: 'I am a dedicated civil engineer specializing in infrastructure development and project management. My expertise includes designing sustainable structures and overseeing large scale construction projects.',
        borderColor: '#9c27b0'
      },
    ],
    subItems: [
      {
        id: 11,
        name: 'Helmet Shell',
        date: '05.13.2023',
        programs: 'SolidWorks',
        creators: [
          { 
            name: 'Ashley Kawitkcs', 
            avatar: require('../../../assets/admin/table-images/image2.png'), 
            email: 'AKawitkcs@email.com',
            role: 'Electrical Engineer',
            bio: 'An electrical engineer with a focus on designing advanced electrical systems for space applications.',
            borderColor: '#03a9f4'
          },
        ],
      },
    ]
  },
  {
    id: 2,
    name: 'Boots',
    date: '05.18.2023',
    programs: 'AutoCAD',
    creators: [
      { 
        name: 'Ashley K', 
        avatar: require('../../../assets/admin/table-images/image2.png'),
        email: 'AK@example.com',
        role: 'Lead Engineer',
        bio: 'Specializes in space-grade boots with high durability and mobility.',
        borderColor: '#03a9f4' 
      },
    ]
  },
  {
    id: 3,
    name: 'Chest Piece',
    date: '01.10.2022',
    programs: 'AutoCAD, Blender',
    creators: [
      { 
        name: 'Alex L', 
        avatar: require('../../../assets/admin/table-images/image3.png'), 
        email: 'alex@example.com',
        role: 'Mechanical Engineer',
        bio: 'Expert in the design of mechanical components for spacesuits.',
        borderColor: '#ffeb3b' 
      },
    ]
  }
];


export default function NestedTable() {
  const [expandedRows, setExpandedRows] = useState({});

  const [modalShow, setModalShow] = useState(false); // State to manage modal visibility
  const [selectedCreator, setSelectedCreator] = useState(null); // State to hold selected creator details


  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  const handleCreatorClick = (creator) => {
    setSelectedCreator(creator);
    setModalShow(true); // Show the modal
  };


  const renderRows = (items, level = 0) => {
    return items.map(item => (
      <React.Fragment key={item.id}>
        <tr className={`level-${level}`}>
          <td>
            {item.subItems && item.subItems.length > 0 && (
              <span className="expand-icon" onClick={() => toggleRow(item.id)}>
                {expandedRows[item.id] ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}
              </span>
            )}
          </td>
          <td>{item.name}</td>
          <td>{item.date}</td>
          <td>{item.programs}</td>
          <td>
          <div className="creators">
              {item.creators.map((creator, index) => (
                <span 
                  key={index} 
                  className="creator-badge" 
                  style={{ borderColor: creator.borderColor }}
                  onClick={() => handleCreatorClick(creator)} // Handle click event
                >
                  <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                  {creator.name}
                </span>
              ))}
            </div>
          </td>
        </tr>
        {expandedRows[item.id] && item.subItems && renderRows(item.subItems, level + 1)}
      </React.Fragment>
    ));
  };

  return (
    <div className="nested-table-container">
      <table responsive className="nested-table">
        <thead>
          <tr>
            <th></th>
            <th>Item</th>
            <th>Date</th>
            <th>Programs</th>
            <th>Creators</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(data)}
        </tbody>
      </table>
      {/* Tooltip Modal for Creator */}
      {selectedCreator && (
        <CreatorTooltipModal 
          show={modalShow} 
          handleClose={() => setModalShow(false)} 
          creator={selectedCreator} 
        />
      )}
    </div>
  );
}
