import React, { useState } from 'react';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { designData } from '../../../data/designData';
import { useNavigate } from 'react-router-dom';
import './Design.css';

export default function Design() {
  const [expandedRows, setExpandedRows] = useState({});

  const navigate = useNavigate();

  const handleUploadClick = () => {
    navigate("/admin/upload-design"); // Programmatically navigate to the upload-design route
  };

  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  const handleItemClick = (item) => {
    if (item.name === 'Visor Piece') {
      navigate('/admin/design-stages'); // Navigate to the specified route
    }
  };

  const renderRows = (items, level = 0) => {
    return items.map(item => (
      <React.Fragment key={item.id}>
        <tr className={`level-${level}`}>
          <td>
            <input type="checkbox" className="design-custom-checkbox" />
          </td>
          <td onClick={() => toggleRow(item.id)} style={{ cursor: 'pointer' }}>
            {expandedRows[item.id] ? <i className='fa fa-angle-down'></i> : <i className='fa fa-angle-right'></i>} {item.name}
          </td>
          <td>{item.lastUpdated}</td>
          <td>{item.program}</td>
          <td>{item.fileType}</td>
          <td>
            <div className="creators">
              {item.creators && item.creators.length > 0 ? (
                item.creators.map((creator, index) => (
                  <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                    <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                    {creator.name}
                  </span>
                ))
              ) : (
                <span>No creators assigned</span>
              )}
            </div>
          </td>
        </tr>
        {/* Recursively render subItems */}
        {expandedRows[item.id] && item.subItems && item.subItems.length > 0 && renderRows(item.subItems, level + 1)}
        {/* Render details if available */}
        {expandedRows[item.id] && item.details && item.details.length > 0 && item.details.map(detail => (
          <tr key={detail.id} className={`level-${level + 1}`} onClick={() => handleItemClick(detail)}>
            <td></td>
            <td>{detail.name}</td>
            <td>{detail.lastUpdated}</td>
            <td>{detail.program}</td>
            <td>{detail.fileType}</td>
            <td>
              <div className="creators">
                {detail.creators && detail.creators.length > 0 ? (
                  detail.creators.map((creator, index) => (
                    <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                      <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                      {creator.name}
                    </span>
                  ))
                ) : (
                  <span>No creators assigned</span>
                )}
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };
  

  

  

  return (
    <Container fluid className="design-container">
      {/* Previous Work and Inspiration Section */}
      <Row className="top-section">
        <Col md={6}>
          <div className="section-header">
            <h4>Previous Work</h4>
            <div className="menu-icon">
              <Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
            </div>
          </div>
          <div className="design-divider"></div>
          <div className="previous-work mt-3">
            <div className="image-card">
              <img src={require('../../../assets/admin/design/TechPack.png')} className="work-image" alt="Tech Pack" />
              <div className="image-overlay">
                <div className="image-text">Tech Pack</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/HellmetShell.png')} className="work-image" alt="Helmet Shell" />
              <div className="image-overlay">
                <div className="image-text">Helmet Shell</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Body.png')} className="work-image" alt="Body Type A Sketches" />
              <div className="image-overlay">
                <div className="image-text">Body Type A Sketches</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Visor.png')} className="work-image" alt="Visor Hand Renderings" />
              <div className="image-overlay">
                <div className="image-text">Visor Hand Renderings</div>
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="section-header">
            <h4>Inspiration</h4>
            <div className="menu-icon">
              <Button variant="btn btn-outline-light m-1" style={{ width: '50px', borderRadius: '20px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
            </div>
          </div>
          <div className="design-divider"></div>
          <div className="inspiration mt-3">
            <div className="image-card">
              <img src={require('../../../assets/admin/design/color.png')} className="inspiration-image" alt="Color Demonstration" />
              <div className="image-overlay">
                <div className="image-text">Color Demonstration</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Constrast.png')} className="inspiration-image" alt="Contrast Example" />
              <div className="image-overlay">
                <div className="image-text">Contrast Example</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Pattern.png')} className="inspiration-image" alt="Pattern" />
              <div className="image-overlay">
                <div className="image-text">Pattern</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Rocket_Engine.png')} className="inspiration-image" alt="Rocket Engine" />
              <div className="image-overlay">
                <div className="image-text">Rocket Engine</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Use_Of_Limited.png')} className="inspiration-image" alt="Use of limited colors" />
              <div className="image-overlay">
                <div className="image-text">Use of limited colors</div>
              </div>
            </div>
            <div className="image-card">
              <img src={require('../../../assets/admin/design/Industrial.png')} className="inspiration-image" alt="Industrial Strategy" />
              <div className="image-overlay">
                <div className="image-text">Industrial Strategy</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/* Assets Section */}
      <Row className="assets-section mt-3">
        <Col md={12}>
          <div className="section-header mb-3">
            <h4>Assets</h4>
            <div className="design-table-actions">
              <Button variant="light" className='w-25' onClick={handleUploadClick}>Upload</Button>
              <Button variant="btn btn-outline-light" style={{ width: '100px' }}>Edit</Button>
              <Form.Control type="text" placeholder="Search" className="assets-search" />
              <Button variant="btn btn-outline-light m-1" style={{ width: '60px' }}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
              <Button variant="btn btn-outline-light m-1" style={{ width: '60px' }}><img src={require('../../../assets/admin/requirement/downloadImage.png')} /></Button>
            </div>
          </div>
          <div className="design-divider"></div>
          <div className='design-table-data'>
          <Table responsive className="design-table mt-4">
            <thead>
              <tr className='border'>
                <th></th>
                <th>Assets</th>
                <th>Last Updated</th>
                <th>Program</th>
                <th>File Type</th>
                <th>Creators</th>
              </tr>
            </thead>
            <tbody>
              {renderRows(designData)}
            </tbody>
          </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
