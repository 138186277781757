import React from 'react';
import { Modal } from 'react-bootstrap';
import './CreatorTooltipModal.css'; // Ensure you add relevant styles here

export default function CreatorTooltipModal({ show, handleClose, creator }) {

  return (
    <Modal show={show} onHide={handleClose} size="md" centered dialogClassName="creator-tooltip-modal">
      <Modal.Body>
        <div className="tooltip-header">
          {/* Creator Avatar with Active Status */}
          <div className="creator-avatar-section">
            <div className="creator-avatar-wrapper">
              <img src={creator.avatar} alt={creator.name} className="creator-avatar-large" />
              <span className="active-status-circle" style={{ borderColor: creator.borderColor }}></span>
            </div>
            <div className="creator-info">
              <h5 className="creator-name">{creator.name}</h5>
              <a href={`mailto:${creator.email}`} className="creator-email">{creator.email}</a>
            </div>
          </div>

          {/* Bio Section */}
          <p className="creator-bio">{creator.bio}</p>

          {/* Horizontal Divider */}
          <div className="horizontal-divider"></div>

          {/* Interaction Buttons */}
          <div className="interaction-section">
            <input type="text" className="message-input" placeholder="Send a message" />
            <div className="icon-group">
              <i className="fa fa-phone"></i>
              <i className="fa fa-video"></i>
            </div>
          </div>

          {/* Footer */}
          <div className="creator-role">
            {creator.role}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
