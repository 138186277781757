import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import {Link, useNavigate } from 'react-router-dom'; // For React Router v6
import axios from 'axios'; // Import axios for HTTP requests
import './Login.css'; // Custom CSS for additional styling
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';
import { API_BASE_URL } from '../../config/config';
export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({}); // To store errors
  const [apiError, setApiError] = useState(''); // To store API error messages
  const navigate = useNavigate(); // For navigation

  const handleLoginClick = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate email
    if (!email) {
      newErrors.email = 'Email is required';
    }

    // Validate password
    if (!password) {
      newErrors.password = 'Password is required';
    }

    // If there are any validation errors, set the error state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Clear any previous error messages
      setErrors({});
      setApiError('');

      try {
        // Make the API request
        const response = await axios.post(`${API_BASE_URL}/users/login`, {
          email,
          password,
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        

        // If login is successful, save the token and navigate to the home route
        if (response.data.token) {
          // Save the token to local storage (or another secure place)
          localStorage.setItem('token', response.data.token);

          // Navigate to the home route
          navigate('/admin/home');
        }
      } catch (error) {
        // Handle API errors
        if (error.response && error.response.data && error.response.data.msg) {
          setApiError(error.response.data.msg);
        } else {
          setApiError('An error occurred while trying to log in. Please try again.');
        }
      }
    }
  };

  return (
    <div className="login-container">
      <div className="background-image"></div> {/* Isolated background image */}
      <div className="background-overlay"></div> {/* Overlay with gradient */}
      <div className="shiny-glow"></div> {/* Shiny glow effect */}
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="text-center">
            <img src={WhiteLogo} alt="Metakosmos Logo" className="logo mb-4" /> {/* Replace with your actual logo */}
            <p className="tagline text-white">Unified Receptive Production System</p>
            <Form className="login-form">
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="text-white">Login</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="example@metakosmos.au"
                  className="input-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)} // Set email value
                  isInvalid={!!errors.email}
                />
                {/* Email error message */}
                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mb-4">
                <Form.Label className="text-white">Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="••••••••"
                  className="input-field"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} // Set password value
                  isInvalid={!!errors.password}
                />
                {/* Password error message */}
                {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
              </Form.Group>

              {/* Display API Error Message */}
              {apiError && <div className="text-danger mb-3">{apiError}</div>}

              {/* Customized Button */}
              <div className="login-button-container" onClick={handleLoginClick}>
                <img
                  src={require('../../assets/login/images/image-5.png')}
                  alt="Enter Button Background"
                  className="login-button-image"
                />
                <span className="login-button-text">Enter</span>
              </div>
            </Form>
            
            {/* Add the link to the Register page */}
            <div className="text-center mt-3">
              <p className="text-light">
                Don't have an account?{' '}
                <Link to="/register" className="text-primary">
                  Register here
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
