export const requirementsData = [
  {
    id: '1',
    name: 'Helmet',
    lastUpdated: '05.13.2023',
    progress: '10 of 16 Completed',
    creators: [
      { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#CA00DC' },
      // { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }
    ],
    subItems: [
      {
        id: '1-1',
        name: 'Features',
        lastUpdated: '',
        progress: '',
        creators: [],
        subItems: [
          {
            id: '1-1-1',
            name: 'Life Support',
            lastUpdated: '',
            progress: '',
            creators: [],
            details: [
              { id: '1-1-1-1', name: 'Peripheral Vision Enhancement', lastUpdated: '05.13.2023', progress: '9 of 13 Completed', creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#CA00DC' }] },
              { id: '1-1-1-2', name: 'Safety Requirements', lastUpdated: '01.10.2022', progress: '8 of 11 Completed', creators: [{ name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'), borderColor: '#0094FF' }] }
            ]
          }
        ]
      }
    ]
  },
  {
    id: '2',
    name: 'Boots',
    lastUpdated: '05.18.2023',
    progress: '10 of 10 Completed',
    creators: [{ name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'),borderColor: '#0094FF' }]
  },
  {
    id: '3',
    name: 'Chest Piece',
    lastUpdated: '12.01.2022',
    progress: '7 of 7 Completed',
    creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'), borderColor: '#F2DA05' }]
  },
  {
    id: '4',
    name: 'Helmet Lighting Design',
    lastUpdated: '08.29.2022',
    progress: '2 of 5 Completed',
    creators: [
      { name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#CA00DC' },
      { name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'),borderColor: '#0094FF' }
    ]
  },
  {
    id: '5',
    name: 'Gauntlet Design',
    lastUpdated: '05.15.2022',
    progress: '2 of 7 Completed',
    creators: [{ name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'),borderColor: '#F2DA05' }]
  },
  {
    id: '6',
    name: 'Glove Design',
    lastUpdated: '02.27.2022',
    progress: '11 of 11 Completed',
    creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#CA00DC' }]
  },
  {
    id: '7',
    name: 'Interior Lining Design',
    lastUpdated: '08.11.2022',
    progress: '1 of 3 Completed',
    creators: [{ name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'),borderColor: '#0094FF' }]
  },
  {
    id: '8',
    name: 'Temperature Regulation System',
    lastUpdated: '07.12.2022',
    progress: '13 of 13 Completed',
    creators: [{ name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'),borderColor: '#F2DA05' }]
  },
  {
    id: '9',
    name: 'Outer Shell Design',
    lastUpdated: '09.18.2022',
    progress: '22 of 22 Completed',
    creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#CA00DC' }]
  },
  {
    id: '10',
    name: 'Life Support Interface Design',
    lastUpdated: '08.08.2022',
    progress: '6 of 20 Completed',
    creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#0094FF' }]
  },
  {
    id: '11',
    name: 'Harness Design',
    lastUpdated: '11.01.2022',
    progress: '5 of 5 Completed',
    creators: [{ name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'),borderColor: '#F2DA05' }]
  },
  {
    id: '12',
    name: 'Internal Storage Design',
    lastUpdated: '11.21.2022',
    progress: '9 of 12 Completed',
    creators: [{ name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'), borderColor: '#CA00DC'}]
  },
  {
    id: '13',
    name: 'Boot Design',
    lastUpdated: '12.25.2022',
    progress: '10 of 10 Completed',
    creators: [{ name: 'Alex L', avatar: require('../assets/admin/table-images/image3.png'),borderColor: '#0094FF'  }]
  },
  {
    id: '14',
    name: 'Interior Lining Design',
    lastUpdated: '09.13.2022',
    progress: '2 of 13 Completed',
    creators: [{ name: 'Ashley K', avatar: require('../assets/admin/table-images/image2.png'),borderColor: '#F2DA05'  }]
  },
  {
    id: '15',
    name: 'Leg Design',
    lastUpdated: '09.27.2022',
    progress: '3 of 3 Completed',
    creators: [{ name: 'Michelle P', avatar: require('../assets/admin/table-images/image1.png'),borderColor: '#CA00DC' }]
  }
];