import React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Table, FormControl, InputGroup, Nav } from 'react-bootstrap';
import './BillOfMaterials.css';
import { FaEdit, FaSearch, FaDownload, FaPrint } from 'react-icons/fa';

export default function BillOfMaterials() {

  const [activeTab, setActiveTab] = useState('finished-products');

  const navigate = useNavigate(); // For navigation

  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const handleRouteNavigation = (value) => {

    if (value === 'bill') {
      navigate('/admin/production-bill-of-materials')
    } else if (value === 'order') {
      navigate('/admin/production-order');
    } else {
      navigate('/admin/production-routing');
    }

  }

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  return (
    <Container fluid className="bill-of-materials-container">
      {/* Top Navigation Section */}
      <Row className="top-navigation">
        <Col md={12}>
          <div className="breadcrumb-bill-of-materials">
            <span className={`breadcrumb-item-bill-of-materials ${isActive('/admin/production-bill-of-materials') ? 'active' : ''}`} onClick={() => handleRouteNavigation('bill')}>Bill of Materials</span>
            <span className="breadcrumb-arrow-routing"><i class="routing-arrow-icon"></i></span>
            <span className="breadcrumb-item-bill-of-materials" onClick={() => handleRouteNavigation('routing')}>Routings</span>
            <span className="breadcrumb-arrow-routing"><i class="routing-arrow-icon"></i></span>
            <span className="breadcrumb-item-bill-of-materials" onClick={() => handleRouteNavigation('order')}>Production Orders</span>
          </div>
        </Col>
      </Row>

      {/* Main Content Section */}
      <Row>
        <Col md={6}>
          <Row>
            <Col md={12}>
              <div className="production-form-bill">
                <h5 className='text-white'>Add</h5>
                <Form className="add-form">
                  <Form.Group controlId="formName" className="form-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" className="form-input" />
                  </Form.Group>
                  <Form.Group controlId="formRouting" className="form-group">
                    <Form.Label>Routings</Form.Label>
                    <Form.Control as="select" className="form-input">
                      <option>Select Routing</option>
                      <option>Routing 1</option>
                      <option>Routing 2</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formFolder" className="form-group">
                    <Form.Label>Folder</Form.Label>
                    <Form.Control as="select" className="form-input">
                      <option>Select Folder</option>
                      <option>Folder 1</option>
                      <option>Folder 2</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formComments" className="form-group">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control as="textarea" rows={3} className="form-input" />
                  </Form.Group>
                  <div className="form-buttons">
                    <Button variant="outline-light" className="clear-button">Clear</Button>
                    <Button variant="primary" className="save-button">Save</Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={12} className="operation-costs">
              <h5 className='section-title'>Operation Costs</h5>
              <div className="costs-table-container">
                <Table bordered variant='dark' className="costs-table">
                  <thead>
                    <tr>
                      <th className="table-header">Name</th>
                      <th className="table-header">Cost (thousands)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-row">
                      <td className="table-cell">High Performance Fabrics</td>
                      <td className="table-cell">3.2</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Insulation Materials</td>
                      <td className="table-cell">29</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Seals and Gaskets</td>
                      <td className="table-cell">18.1</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Laser Cutters</td>
                      <td className="table-cell">3.6</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Industrial Sewing Machines</td>
                      <td className="table-cell">2.1</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Testing Facilities</td>
                      <td className="table-cell">5.6</td>
                    </tr>
                    <tr className="table-row">
                      <td className="table-cell">Quality Assurance Processes</td>
                      <td className="table-cell">2.1</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>


          </Row>
        </Col>
        <Col md={6} className='finished-products-container-bill-of-materials'>
          <Container fluid className="bill-container">
            {/* Tabs Navigation */}
            <Nav variant="tabs" defaultActiveKey="finished-products" className="custom-tabs mb-4">
              <Nav.Item>
                <Nav.Link eventKey="finished-products" className="tab-link">
                  Finished Products
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="raw-materials" className="tab-link">
                  Raw Materials
                </Nav.Link>
              </Nav.Item>
              <Row>
              <Col md={12} className="d-flex justify-content-end align-items-center">
                <Button variant="btn btn-outline-light rounded-pill w-25" className="control-button me-2">
                  {/* <FaEdit /> */}
                  Edit
                </Button>
                <InputGroup className="search-box me-2">
                  <FormControl placeholder="Search" aria-label="Search" />
                  {/* <Button variant="secondary">
                    <FaSearch />
                  </Button> */}
                </InputGroup>
                <Button variant="btn-outline-light rounded-pill border w-25" className="control-button me-2">
                  {/* <FaDownload /> */}
                  <img src={require('../../../../assets/admin/requirement/downloadImage.png')}></img>
                </Button>
                <Button variant="btn-outline-light border rounded-pill w-25 text-white" className="control-button">
                  <FaPrint />
                </Button>
              </Col>
              </Row>
            </Nav>

            {/* Description and Controls */}
            <Row className="align-items-center mb-3">
              <Col md={8}>
                <p className="product-description">
                  The Finished Products section lists all essential components of the space suit,
                  ensuring they are assembled and tested for safety, functionality, and durability.
                </p>
              </Col>
            </Row>

            {/* Data Table */}
            <Table responsive bordered variant='dark' hover className="finished-products-table">
              <thead>
                {/* First Header Row */}
                <tr>
                  <th>Name</th>
                  <th>Folder</th>
                  <th className="text-end">Quota</th>
                </tr>

                {/* Second Header Row (Visor, Helmet, 1 per suit) */}
                <tr>
                  <th>Visor</th>
                  <th>Helmet</th>
                  <th className="text-end">1 per suit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>00218</td>
                  <td>Outer Shell Fabric</td>
                  <td className="text-end">10 meters</td>
                </tr>
                <tr>
                  <td>87587</td>
                  <td>Thermal Insulation Layers</td>
                  <td className="text-end">5 sq meters</td>
                </tr>
                <tr>
                  <td>88927</td>
                  <td>Pressure Bladder</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>82701</td>
                  <td>Helmet</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>39218</td>
                  <td>Life Support System</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>03827</td>
                  <td>Communication System</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>10286</td>
                  <td>Cooling Garment</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>09672</td>
                  <td>Seals and Zippers</td>
                  <td className="text-end">15 per suit</td>
                </tr>
                <tr>
                  <td>29021</td>
                  <td>Electronic Controls</td>
                  <td className="text-end">1 per suit</td>
                </tr>
                <tr>
                  <td>09182</td>
                  <td>Sensors</td>
                  <td className="text-end">10 per suit</td>
                </tr>
                <tr>
                  <td>01933</td>
                  <td>Radiation Shielding</td>
                  <td className="text-end">2 sq meters</td>
                </tr>
                <tr>
                  <td>00105</td>
                  <td>Harness System</td>
                  <td className="text-end">2 sq meters</td>
                </tr>
              </tbody>
            </Table>

          </Container>
        </Col>


      </Row>
    </Container>
  );
}
