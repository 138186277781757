import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button, Form } from 'react-bootstrap';
import './Account.css';
import Schedule from '../../common/Schedule/Schedule';
import Calendar from '../Calendar/Calendar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Account() {
  const [userData, setUserData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedData, setUpdatedData] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage
        const response = await axios.get('https://urps.kosmosuit.com/api/users/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
        setUpdatedData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          user_type: response.data.user_type,
          email: response.data.email, // Add email here
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
        if (error.response && error.response.status === 401) {
          // If the token is invalid or expired, redirect to login
          localStorage.removeItem('token'); // Remove the invalid token
          navigate('/login'); // Redirect to login page
        }
      }
    };

    fetchData();
  }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleInputChange = (e) => {
    setUpdatedData({ ...updatedData, [e.target.name]: e.target.value });
  };

  const handleSaveClick = async () => {
    if (!updatedData.user_type) {
      setErrors({ user_type: 'Please select a role' });
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await axios.put('https://urps.kosmosuit.com/api/users/me', updatedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(updatedData);
      setEditMode(false);
      setErrors({});
    } catch (error) {
      console.error('Error updating user data:', error);

      if (error.response && error.response.status === 401) {
        // If the token is invalid or expired, redirect to login
        localStorage.removeItem('token'); // Remove the invalid token
        navigate('/login'); // Redirect to login page
      }
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setUpdatedData({
      first_name: userData.first_name,
      last_name: userData.last_name,
      user_type: userData.user_type,
    });
    setErrors({});
  };

  if (!userData) return null;

  return (
    <Container fluid className="account-container">
      <div className="account-background-image"></div>
      <Row className="profile-section">
        <Col md={12} className="profile-details">
          <Image
            src={require('../../../assets/admin/account/image-9.png')}
            roundedCircle
            className="profile-image"
          />
        </Col>
      </Row>
      <Row className="profile-name">
        <Col md={6}>
          <Row>
            <Col md={10}>
              <h2>
                {editMode ? (
                  <Form.Control
                    type="text"
                    name="first_name"
                    className="message-input"
                    value={updatedData.first_name}
                    onChange={handleInputChange}
                  />
                ) : (
                  userData.first_name
                )}{' '}
                {editMode ? (
                  <Form.Control
                    type="text"
                    className="message-input"
                    name="last_name"
                    value={updatedData.last_name}
                    onChange={handleInputChange}
                  />
                ) : (
                  userData.last_name
                )}
              </h2>
              <a href={`mailto:${userData.email}`} className="email-link">
                {userData.email}
              </a>
            </Col>
            {!editMode && (
              <Col md="2">
                <Button
                  variant="link"
                  className="edit-button float-end"
                  onClick={handleEditClick}
                >
                  Edit
                </Button>
              </Col>
            )}
          </Row>

          <p className="team-manager">
            {editMode ? (
              <Form.Group controlId="formUserType" className="mb-3">
              <Form.Label>Select Role</Form.Label>
              <Form.Control
                as="select"
                className="input-field"
                name="user_type"
                value={updatedData.user_type}
                onChange={handleInputChange}
                isInvalid={!!errors.user_type}
              >
                <option value="">Select Role</option>
                <option value="design">Design</option>
                <option value="engineering">Engineering</option>
                <option value="production">Production</option>
                <option value="quality">Quality</option>
                <option value="testing">Testing</option>
                <option value="maintenance">Maintenance</option>
              </Form.Control>
              {errors.user_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.user_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            
            ) : (
              userData.user_type
            )}
          </p>
          <Row>
            {!editMode && (
              <Col md={6}>
                <Form.Control
                  type="text"
                  placeholder="Send a message"
                  className="message-input"
                />
              </Col>
            )}
            {editMode && (
              <Row className="save-cancel-buttons">
                <Col md={6}>
                  <Button variant="primary" onClick={handleSaveClick}>
                    Save
                  </Button>
                  <Button variant="secondary" onClick={handleCancelClick} className="ms-2">
                    Cancel
                  </Button>
                </Col>
              </Row>
            )}

            {!editMode && (
              <Col md={6}>
                <div className="contact-icons float-end">
                  <img
                    className="contact-icon"
                    src={require('../../../assets/admin/account/image 25.png')}
                    alt="Contact Icon 1"
                  />
                  <img
                    className="contact-icon"
                    src={require('../../../assets/admin/account/image 44.png')}
                    alt="Contact Icon 2"
                  />
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col md={6} className="interests-career">
          <div className="vertical-line"></div>

          <Row>
            <Col md={12}>
              <div className="interests">
                <h5>Interests</h5>
                <p>Bouldering, Running, Comedy Movies, Cooking</p>
              </div>
            </Col>
            <Col md={12}>
              <div className="career">
                <h5>Career</h5>
                <p>
                  I have a strong background in leading cross-functional teams
                  in the aerospace industry. My expertise lies in project
                  management, strategic planning, and optimizing team
                  performance. My goal is to empower individuals and align team
                  efforts with organizational success, ensuring the seamless
                  execution of high-impact projects in dynamic environments.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Schedule />
          {/* <Calendar /> */}
        </Col>
      </Row>
    </Container>
  );
}
