
export const orders = [
    { date: "02.19.23", time: "15:21", notes: "Resupplying fabrics", amount: "2,981.00", startDate: "07.19.23", endDate: "09.29.23", status: "Started", creator: "Michelle P", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "05.21.23", time: "19:15", notes: "Resupplying Polycarbonate", amount: "3,131.00", startDate: "07.19.23", endDate: "07.19.23", status: "Started", creator: "Ashley K", avatar: require('../assets/admin/table-images/image2.png') },
    { date: "01.17.23", time: "18:23", notes: "Kevlar", amount: "1,827.00", startDate: "05.21.23", endDate: "02.19.23", status: "Finished", creator: "Alex L", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "09.29.23", time: "13:02", notes: "Carbon Fiber 1 restock", amount: "12,981.00", startDate: "01.17.23", endDate: "05.21.23", status: "Finished", creator: "Michelle P", avatar: require('../assets/admin/table-images/image3.png') },
    { date: "08.12.23", time: "20:18", notes: "Resupplying fabrics", amount: "10,982.00", startDate: "09.29.23", endDate: "01.17.23", status: "Started", creator: "Ashley K", avatar: require('../assets/admin/table-images/image2.png') },
    { date: "05.30.23", time: "23:52", notes: "Carbon fiber 2", amount: "9,827.00", startDate: "08.12.23", endDate: "09.29.23", status: "Finished", creator: "Alex L", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "07.19.23", time: "20:58", notes: "Resupplying sensors", amount: "8,619.00", startDate: "05.30.23", endDate: "08.12.23", status: "Started", creator: "Michelle P", avatar: require('../assets/admin/table-images/image3.png') },
    { date: "03.23.23", time: "21:18", notes: "Resupplying polyester", amount: "6,256.00", startDate: "07.19.23", endDate: "05.30.23", status: "Started", creator: "Ashley K", avatar: require('../assets/admin/table-images/image2.png') },
    { date: "03.15.23", time: "16:03", notes: "Resupplying aerogel", amount: "3,591.00", startDate: "03.23.23", endDate: "07.19.23", status: "Finished", creator: "Alex L", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "02.25.23", time: "13:01", notes: "Carbon Fiber 3", amount: "2,290.00", startDate: "03.15.23", endDate: "03.23.23", status: "Finished", creator: "Michelle P", avatar: require('../assets/admin/table-images/image3.png') },
    { date: "01.03.23", time: "13:28", notes: "Carbon Fiber 2", amount: "2,983.00", startDate: "02.25.23", endDate: "03.15.23", status: "Started", creator: "Ashley K", avatar: require('../assets/admin/table-images/image2.png') },
    { date: "01.22.23", time: "15:39", notes: "Rubber compounds", amount: "3,311.00", startDate: "01.03.23", endDate: "02.25.23", status: "Finished", creator: "Alex L", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "06.28.22", time: "23:11", notes: "Kevlar", amount: "11,292.00", startDate: "01.22.23", endDate: "01.03.23", status: "Finished", creator: "Michelle P", avatar: require('../assets/admin/table-images/image3.png') },
    { date: "07.26.22", time: "16:59", notes: "Resupplying sensors", amount: "31,000.00", startDate: "06.28.22", endDate: "01.22.23", status: "Finished", creator: "Alex L", avatar: require('../assets/admin/table-images/image1.png') },
    { date: "12.29.22", time: "18:30", notes: "Resupplying Aerogel", amount: "7,329.00", startDate: "07.26.22", endDate: "06.28.22", status: "Finished", creator: "Ashley K", avatar: require('../assets/admin/table-images/image2.png') },
    { date: "09.13.22", time: "12:52", notes: "Resupplying Polycarbonate", amount: "8,233.00", startDate: "12.29.22", endDate: "07.26.22", status: "Finished", creator: "Michelle P", avatar: require('../assets/admin/table-images/image3.png') },
  ];