import React from 'react';
import './FileDropZone.css';

export default function FileDropZone() {
  const handleDrop = (event) => {
    event.preventDefault();
    // Handle file drop logic here
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div
      className="file-drop-zone"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <p className="drop-text">Drop your file here</p>
      <div className="arrow-down">↓</div>
    </div>
  );
}
