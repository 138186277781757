import React, { useState } from 'react';
import { Container, Row, Col, Form, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; // For React Router v6
import axios from 'axios'; // Import axios for HTTP requests
import './Register.css'; // Custom CSS for additional styling
import WhiteLogo from '../../assets/login/images/metakosmos_white_logo.png';
import { API_BASE_URL } from '../../config/config';

export default function Register() {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    user_type: '',
    age: '',
    terms_condition: false,
    newsletter: false,
  });
  const [errors, setErrors] = useState({}); // To store errors
  const [apiError, setApiError] = useState(''); // To store API error messages
  const navigate = useNavigate(); // For navigation

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleRegisterClick = async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate fields
    if (!formData.first_name) newErrors.first_name = 'First name is required';
    if (!formData.last_name) newErrors.last_name = 'Last name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.phone_number) newErrors.phone_number = 'Phone number is required';
    if (!formData.password) newErrors.password = 'Password is required';
    if (!formData.user_type) newErrors.user_type = 'User type is required';
    if (!formData.age || formData.age < 16) newErrors.age = 'Age must be above 16';
    if (!formData.terms_condition) newErrors.terms_condition = 'You must accept the terms and conditions';

    // If there are any validation errors, set the error state
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Clear any previous error messages
      setErrors({});
      setApiError('');

      try {
        // Make the API request
        const response = await axios.post(`${API_BASE_URL}/users/register`, formData, {
          headers: {
            'Accept': 'application/json',
          },
        });        

        // If registration is successful, navigate to the login route
        if (response.status === 201) {
          navigate('/login');
        }
      } catch (error) {
        // Handle API errors
        if (error.response && error.response.data && error.response.data.msg) {
          setApiError(error.response.data.msg);
        } else {
          setApiError('An error occurred while trying to register. Please try again.');
        }
      }
    }
  };

  return (
    <div className="register-container">
      <div className="background-image"></div> {/* Isolated background image */}
      <div className="background-overlay"></div> {/* Overlay with gradient */}
      <div className="shiny-glow"></div> {/* Shiny glow effect */}
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            {/* <Card className="p-4"> */}
              <div className="text-center mb-4">
                <img src={WhiteLogo} alt="Metakosmos Logo" className="logo mb-4" /> {/* Replace with your actual logo */}
                <p className="tagline text-light">Unified Receptive Production System</p>
              </div>
              <Form className="register-form">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName" className="mb-3">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className="input-field"
                        name="first_name"
                        value={formData.first_name}
                        onChange={handleChange}
                        isInvalid={!!errors.first_name}
                      />
                      {errors.first_name && <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formLastName" className="mb-3">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="input-field"
                        name="last_name"
                        value={formData.last_name}
                        onChange={handleChange}
                        isInvalid={!!errors.last_name}
                      />
                      {errors.last_name && <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formEmail" className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="example@metakosmos.au"
                        className="input-field"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                      />
                      {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPhoneNumber" className="mb-3">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone Number"
                        className="input-field"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        isInvalid={!!errors.phone_number}
                      />
                      {errors.phone_number && <Form.Control.Feedback type="invalid">{errors.phone_number}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formPassword" className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="••••••••"
                        className="input-field"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        isInvalid={!!errors.password}
                      />
                      {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formAge" className="mb-3">
                      <Form.Label>Age</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Age"
                        className="input-field"
                        name="age"
                        value={formData.age}
                        onChange={handleChange}
                        isInvalid={!!errors.age}
                      />
                      {errors.age && <Form.Control.Feedback type="invalid">{errors.age}</Form.Control.Feedback>}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="formUserType" className="mb-3">
                  <Form.Label>Select Role</Form.Label>
                  <Form.Control
                    as="select"
                    className="input-field"
                    name="user_type"
                    value={formData.user_type}
                    onChange={handleChange}
                    isInvalid={!!errors.user_type}
                  >
                    <option value="">Select Role</option>
                    <option value="design">Design</option>
                    <option value="engineering">Engineering</option>
                    <option value="production">Production</option>
                    <option value="quality">Quality</option>
                    <option value="testing">Testing</option>
                    <option value="maintenance">Maintenance</option>
                  </Form.Control>
                  {errors.user_type && <Form.Control.Feedback type="invalid">{errors.user_type}</Form.Control.Feedback>}
                </Form.Group>

                <Form.Group controlId="formTerms" className="mb-3">
                  <Form.Check
                    type="checkbox"
                    label="I accept the terms and conditions"
                    className="text-light"
                    name="terms_condition"
                    checked={formData.terms_condition}
                    onChange={handleChange}
                    isInvalid={!!errors.terms_condition}
                  />
                  {errors.terms_condition && <Form.Control.Feedback type="invalid">{errors.terms_condition}</Form.Control.Feedback>}
                </Form.Group>

                <Form.Group controlId="formNewsletter" className="mb-4">
                  <Form.Check
                    type="checkbox"
                    label="Subscribe to newsletter"
                    className="text-light"
                    name="newsletter"
                    checked={formData.newsletter}
                    onChange={handleChange}
                  />
                </Form.Group>

                {/* Display API Error Message */}
                {apiError && <div className="text-danger mb-3">{apiError}</div>}

                {/* Customized Button */}
                <div className="register-button-container" onClick={handleRegisterClick}>
                  <img
                    src={require('../../assets/login/images/image-5.png')}
                    alt="Enter Button Background"
                    className="register-button-image"
                  />
                  <span className="register-button-text">Register</span>
                </div>
              </Form>
            {/* </Card> */}

            {/* Add the link to the Login page */}
            <div className="text-center mt-3">
              <p className="text-light">
                Already have an account?{' '}
                <Link to="/login" className="text-primary">
                  Click here to login
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
