import React, { useState } from 'react';
import { Row, Col, Accordion, Card } from 'react-bootstrap';
import XEvasModal from '../DesignStagesModals/XEvasModal/XEvasModal';
import AS9100DModal from '../DesignStagesModals/AS9100DModal/AS9100DModal';
import ASTMModal from '../DesignStagesModals/ASTMModal/ASTMModal';
import './DesignStageDropdown.css';

export default function DesignStageDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalShow, setModalShow] = useState({ xevas: false, as9100d: false, astm: false });

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleModalClose = () => setModalShow({ xevas: false, as9100d: false, astm: false });
  
  const handleModalShow = (modal) => {
    setModalShow(prevState => ({ ...prevState, [modal]: true }));
  };

  return (
    <>
      <Row className="mb-3">
        <Col xs={12}>
          <Card className={`designstage-dropdown bg-dark text-white ${isOpen ? 'open' : ''}`}>
            <Card.Header className="designstage-header" onClick={toggleAccordion}>
              <div className="title">Test List</div>
              <div className="icon">{isOpen ? '▾' : '▸'}</div>
            </Card.Header>
            <Accordion.Collapse in={isOpen}>
              <Card.Body className="designstage-body">
                <div className="designstage-item" onClick={() => handleModalShow('xevas')}>
                  <span>X-EVAS</span>
                  <span className="info-icon">i</span>
                </div>
                <div className="designstage-item" onClick={() => handleModalShow('as9100d')}>
                  <span>AS9100D</span>
                  <span className="info-icon">i</span>
                </div>
                <div className="designstage-item" onClick={() => handleModalShow('astm')}>
                  <span>ASTM</span>
                  <span className="info-icon">i</span>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Col>
      </Row>

      <XEvasModal show={modalShow.xevas} handleClose={handleModalClose} />
      <AS9100DModal show={modalShow.as9100d} handleClose={handleModalClose} />
      <ASTMModal show={modalShow.astm} handleClose={handleModalClose} />
    </>
  );
}
