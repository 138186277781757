import React from "react";


const OptionsPanel = () => {
  return (
    <div className="options-panel">
      <h2>Options</h2>

      <div className="section">
        <h4>View</h4>
        <label><input type="checkbox" /> Wireframe</label>
        <label><input type="checkbox" /> Shaded</label>
        <label><input type="checkbox" defaultChecked /> Rendered</label>
        <label><input type="checkbox" /> Shaded with hidden edges</label>
        <label><input type="checkbox" /> Wireframe with hidden edges</label>
      </div>

      <div className="section">
        <h4>Parameters</h4>
        <p>Type Of Radiation</p>
        <label><input type="checkbox" /> Gamma Rays</label>
        <label><input type="checkbox" /> Protons</label>
        <label><input type="checkbox" /> Neutrons</label>
        <label><input type="checkbox" /> Alpha Particles</label>
        <label><input type="checkbox" /> Electrons</label>
      </div>

      <div className="section">
        <h4>Energy Levels</h4>
        <label><input type="checkbox" /> Low-energy</label>
        <label><input type="checkbox" /> Medium-energy</label>
        <label><input type="checkbox" /> High-energy</label>
      </div>

      <div className="section wide-checkbox-section">
        <h4>Exposure Duration</h4>
        <label><input type="checkbox" className='custom-checkbox' /> Hours</label>
        <label><input type="checkbox" className='custom-checkbox' /> Days</label>
        <label><input type="checkbox" className='custom-checkbox' /> Weeks</label>
      </div>

      <div className="section">
        <h4>Ambient Temperature</h4>
        <label><input type="checkbox" className='custom-checkbox' /> Temperature</label>
      </div>

      <div className="section">
        <h4>Sensor Data</h4>
        <label><input type="checkbox" /> Continuous</label>
      </div>
    </div>
  );
};

export default OptionsPanel;
