import React, { useState } from 'react';
import './Sidebar.css';
import { NavLink, useLocation } from "react-router-dom";
import { Accordion, Card, Button } from 'react-bootstrap';


function Sidebar({ isExpanded }) {
  const [isSettingsExpanded, setIsSettingsExpanded] = useState(false); // State to manage accordion open/close
  const location = useLocation();

  const activeStyle = {
    background: 'linear-gradient(90deg, rgba(42, 42, 42, 0.9), rgba(107, 107, 107, 0.9))',
    color: '#ccc'
  };

  const toggleSettings = () => {
    setIsSettingsExpanded(!isSettingsExpanded);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <ul>
        <li style={location.pathname === '/admin/home' ? activeStyle : {}}>
          <NavLink className="nav-link" to="home" title="Home">
            {/* <i className="fas fa-home"></i> */}
            <img src={require('../../assets/admin/sidebar-icons/home.png')}></img>
            {isExpanded && 'Home'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/calendar' ? activeStyle : {}}>
          <NavLink className="nav-link" to="calendar" title="Calendar">
            {/* <i className="fas fa-calendar"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/calendar.png')}></img>
            {isExpanded && 'Calendar'}
          </NavLink>
        </li>
        <li>
          <a href="#calls" title="Calls">
            {/* <i className="fas fa-phone"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/call.png')}></img>
            {isExpanded && 'Calls'}
          </a>
        </li>
        <li>
          <a href="#messages" title="Messages">
            {/* <i className="fas fa-envelope"></i> */}
            <img src={require('../../assets/admin/sidebar-icons/message.png')}></img>
            {isExpanded && 'Messages'}
          </a>
        </li>
        <li style={location.pathname === '/admin/requirements' ? activeStyle : {}}>
          <NavLink className="nav-link" to="requirments" title="Requirements">
            {/* <i className="fas fa-list"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/requirment.png')}></img>
            {isExpanded && 'Requirements'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/design' ? activeStyle : {}}>
          <NavLink className="nav-link" to="design" title="Design">
            {/* <i className="fas fa-pencil"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/design.png')}></img>
            {isExpanded && 'Design'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/engineering' ? activeStyle : {}}>
          <NavLink className="nav-link" to="engineering" title="Engineering">
            {/* <i className="fas fa-cog"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/engineering.png')}></img>
            {isExpanded && 'Engineering'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/production-routing' ? activeStyle : {}}>
          <NavLink className="nav-link" to="production-routing" title="Production">
            {/* <i className="fas fa-cubes"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/production.png')}></img>
            {isExpanded && 'Production'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/quality-testing' ? activeStyle : {}}>
          <NavLink className="nav-link" to="quality-testing" title="Quality & Testing">
            {/* <i className="fas fa-check"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/quality.png')}></img>
            {isExpanded && 'Quality & Testing'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/maintenance' ? activeStyle : {}}>
          <NavLink className="nav-link" to="maintenance" title="Maintenance">
            {/* <i className="fas fa-wrench"></i>  */}
            <img src={require('../../assets/admin/sidebar-icons/maintenance.png')}></img>
            {isExpanded && 'Maintenance'}
          </NavLink>
        </li>
        <li style={location.pathname === '/admin/account' ? activeStyle : {}}>
          <NavLink className="nav-link" to="account" title="Account">
            {/* <i className="fas fa-user"></i>  */}
            <img style={{borderRadius:'50%'}} src={require('../../assets/admin/sidebar-icons/account.png')}></img>
            {isExpanded && 'Account'}
          </NavLink>
        </li>
        {/* <li style={location.pathname === '/admin/divisions' ? activeStyle : {}}>
          <NavLink className="nav-link" to="divisions" title="Divisions">
            <i className="fas fa-user"></i> {isExpanded && 'Divisions'}
          </NavLink>
        </li> */}
        {/* <li style={location.pathname === '/admin/subdivisions' ? activeStyle : {}}>
          <NavLink className="nav-link" to="subdivisions" title="SubDivisions">
            <i className="fas fa-user"></i> {isExpanded && 'SubDivisions'}
          </NavLink>
        </li> */}
        <li style={location.pathname === '/admin/newrequirement' ? activeStyle : {}}>
          <NavLink className="nav-link" to="newrequirement" title="New Requirement">
            <i className="fas fa-user"></i> {isExpanded && 'New Requirement'}
          </NavLink>
        </li>

        {/* Settings Accordion */}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="d-flex align-items-center">
                {/* <img src={settingsIcon} alt="Settings Icon" /> */}
                {isExpanded && <span className="ml-2">Settings</span>}
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="nav flex-column ml-4">
                <li className="nav-item" style={location.pathname === '/admin/divisions' ? activeStyle : {}}>
                  <NavLink className="nav-link" to="divisions" title="Divisions">
                    <i className="fas fa-user"></i> {isExpanded && 'Divisions'}
                  </NavLink>
                </li>
                <li className="nav-item" style={location.pathname === '/admin/subdivisions' ? activeStyle : {}}>
                  <NavLink className="nav-link" to="subdivisions" title="SubDivisions">
                    <i className="fas fa-user"></i> {isExpanded && 'SubDivisions'}
                  </NavLink>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <li>
                    <a href="#settings" title="Settings">
                     
                        <img src={require('../../assets/admin/sidebar-icons/setting.png')}></img>
                        {isExpanded && 'Settings'}
                    </a>
                </li> */}
      </ul>
    </div>
  );
}

export default Sidebar;
