// src/context/FileContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a context
const FileContext = createContext();

// Custom hook to use the context
export const useFileContext = () => useContext(FileContext);

// FileContext provider
export const FileProvider = ({ children }) => {
  const [files, setFiles] = useState([]);

  // Function to add new files
  const addFile = (file) => {
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  return (
    <FileContext.Provider value={{ files, addFile }}>
      {children}
    </FileContext.Provider>
  );
};
