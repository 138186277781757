import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

export default function InitialInspiration() {
  return (
    <div>
      <Row>
        <Col md={6}>
          <Image 
            src={require('../../../../../../assets/admin/design/design-stages/InitialInspiration/InitialInspirationImage1.png')} 
            fluid 
            alt="Astronaut Placeholder" 
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col md={6}>
              <Image 
                src={require('../../../../../../assets/admin/design/design-stages/InitialInspiration/InitialInspirationImage2.png')} 
                fluid 
                alt="Duo Placeholder" 
              />
            </Col>
            <Col md={6}>
              <Image 
                src={require('../../../../../../assets/admin/design/design-stages/InitialInspiration/InitialInspirationImage3.png')} 
                fluid 
                alt="Rocket Placeholder" 
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Image 
                src={require('../../../../../../assets/admin/design/design-stages/InitialInspiration/InitialInspirationImage4.png')} 
                fluid 
                alt="Tech Background Placeholder" 
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
