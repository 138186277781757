import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import './UploadDesign.css';
import NestedTable from '../../../common/NestedTable/NestedTable';
import { useNavigate } from 'react-router-dom';
export default function UploadDesign() {

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate("/admin/design"); // Programmatically navigate to the upload-design route
  };

  return (
    <div className="upload-design-container">
      {/* Back Button */}
      <div className="upload-design-back-button">
        <Button variant="outline-light" onClick={handleBackClick}>Back</Button>
      </div>

      {/* File Information Section */}
      <div className="file-info-section justify-content-center h6">
        <div className="file-info-icon">
          <i className="fas fa-check-circle"></i>
        </div>
        <div className="file-name">
          <i className="fas fa-question-circle"></i> HelmetModelRoughDraft.f3d
        </div>
      </div>

      {/* Upload Type Section */}
      <div className="upload-type-section">
        <h4>What are you uploading?</h4>
        <Row className='m-3'>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Sketch<br /><span>.jpeg</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              3D Model<br /><span>.obj</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Render<br /><span>.png</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              UI/UX File<br /><span>.fig</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Technical Drawings<br /><span>.dwg</span>
            </Button>
          </Col>
          <Col md={4}>
            <Button variant="outline-light" className="upload-type-button">
              Other...<br /><span>.other</span>
            </Button>
          </Col>
        </Row>
      </div>

      {/* Folder Selection Section */}
      <div className="folder-selection-section">
        <h4 className='text-center'>Select the Folder</h4>
        <div className="folder-table">
          {/* Folder Table Structure */}
          {/* Your folder table implementation goes here */}
          <NestedTable />
        </div>
      </div>

      {/* Upload Button */}
      <div className="upload-button-section">
        <Button variant="primary">Upload</Button>
      </div>
    </div>
  );
}
