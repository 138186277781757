import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { requirementsData } from '../../../data/requirementsData';
import './Requirements.css';



export default function Requirements() {
  const [expandedRows, setExpandedRows] = useState({});
  const navigate = useNavigate(); // Initialize useNavigate hook

  const toggleRow = (rowId) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [rowId]: !prevState[rowId]
    }));
  };

  const handleItemClick = (item) => {
    if (item.name === 'Safety Requirements') {
      navigate('/admin/safety-requirments'); // Navigate to the specified route
    }
  };

  
  const renderRows = (items, level = 0) => {
    return items.map(item => (
      <React.Fragment key={item.id}>
        <tr className={`level-${level}`}>
          <td>
            <input type="checkbox" className="requirement-custom-checkbox" />
          </td>
          <td>
            {/* Add toggle for subItems and details */}
            {(item.subItems || item.details) && (
              <span className="expand-icon" onClick={() => toggleRow(item.id)}>
                {expandedRows[item.id] ? <i className="fa fa-angle-down"></i> : <i className="fa fa-angle-right"></i>}
              </span>
            )}
          </td>
          <td>{item.name}</td>
          <td>{item.lastUpdated}</td>
          <td>{item.progress}</td>
          <td>
            <div className="creators">
              {item.creators.map((creator, index) => (
                <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                  <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                  {creator.name}
                </span>
              ))}
            </div>
          </td>
        </tr>
        
        {/* Recursively render subItems */}
        {expandedRows[item.id] && item.subItems && renderRows(item.subItems, level + 1)}
        
        {/* Rendering details if available */}
        {expandedRows[item.id] && item.details && item.details.map(detail => (
          <tr key={detail.id} className={`level-${level + 1}`} onClick={() => handleItemClick(detail)}>
            <td></td>
            <td></td>
            <td>{detail.name}</td>
            <td>{detail.lastUpdated}</td>
            <td>{detail.progress}</td>
            <td>
              <div className="creators">
                {detail.creators.map((creator, index) => (
                  <span key={index} className="creator-badge" style={{ border: `1px solid ${creator.borderColor}` }}>
                    <img src={creator.avatar} alt="avatar" className="creator-avatar" />
                    {creator.name}
                  </span>
                ))}
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };
  
  

  return (
    <Container fluid className="requirements-container">
      <Row className="top-section">
        <Col md={6}>
          <h4>Assets</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center requirement-buttons">
          <Button variant="light">Create New</Button>
          <Button variant="btn btn-outline-light m-1" style={{width:'80px'}}>Edit</Button>
          <Form.Control type="text" placeholder="Search" className="w-25 requirements-search" />
          <Button variant="btn btn-outline-light m-1" style={{width:'60px'}}><img src={require('../../../assets/admin/requirement/menu.png')} /></Button>
          <Button variant="btn btn-outline-light m-1" style={{width:'60px'}}><img src={require('../../../assets/admin/requirement/downloadImage.png')} /></Button>
        </Col>
      </Row>
      <div className="requirement-divider mb-4"></div>

      <Table responsive className="requirements-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Item</th>
            <th>Last Updated</th>
            <th>Progress</th>
            <th>Creators</th>
          </tr>
        </thead>
        <tbody>
          {renderRows(requirementsData)}
        </tbody>
      </Table>
    </Container>
  );
}
