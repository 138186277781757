import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import TopNavbar from '../TopNavbar/TopNavbar';
import Sidebar from '../Sidebar/Sidebar';
import routes from '../../routes';
import './AdminLayout.css';
import AdminRoute from '../AdminRoute';

function AdminLayout() {
    const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarExpanded(!isSidebarExpanded);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.path}
                        element={
                            <AdminRoute>
                                {prop.component}
                            </AdminRoute>
                        }
                        key={key}
                        exact
                    />
                );
            }
            return null;
        });
    };

    return (
        <div className="admin-layout">
            <TopNavbar toggleSidebar={toggleSidebar} />
            <div className="layout-wrapper">
                <Sidebar isExpanded={isSidebarExpanded} />
                <div className={`main-content ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
                    <Routes>
                        {getRoutes(routes)}
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
