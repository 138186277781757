import React from 'react';
import { Modal, Image } from 'react-bootstrap';
import './ASTMModal.css';

export default function ASTMModal({ show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="astm-modal">
      <Modal.Header closeButton>
        <Modal.Title>ASTM</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Image src={require('../../../../../../assets/admin/design/modal-images/AstmImage.png')} alt="ASTM Logo" className="standard-logo" /> {/* Replace with the actual path to the ASTM logo */}
        <p>The ASTM (American Society for Testing and Materials) standards for materials and fabrics include a wide range of tests to ensure quality and performance. These tests are essential for industries like aerospace, where material reliability and safety are critical.</p>
        <hr />
        <h5>Area:</h5>
        <p>Material and Fabrics</p>
        <h5>Key Requirements</h5>
        <ul>
          <li>
            <strong>Vertical Flame Test (ASTM D6.13):</strong>
            <ul>
              <li><strong>Purpose:</strong> Measures the flame resistance of textiles used in protective clothing.</li>
              <li><strong>Procedure:</strong> A fabric specimen is exposed to a controlled flame and evaluated for afterflame, afterglow, char length, and the occurrence of melting or dripping.</li>
              <li><strong>Application:</strong> Used in standards such as ASTM F1506 for flame-resistant clothing worn by electrical workers and NFPA 2112 for industrial flame-resistant garments. <a href="https://www.tyndaleusa.com" target="_blank" rel="noopener noreferrer">Tyndale USA</a>.</li>
            </ul>
          </li>
          <li>
            <strong>Textile Permeability Test (ASTM D737.18):</strong>
            <ul>
              <li><strong>Purpose:</strong> Determines the air permeability of textile fabrics.</li>
              <li><strong>Procedure:</strong> Measures the airflow through a fabric under a specified pressure differential, reporting results in cm³/s·cm² or ft³/min·ft².</li>
              <li><strong>Application:</strong> Used for evaluating the breathability of materials, crucial for comfort and performance in protective clothing and other applications. <a href="https://www.testex.com" target="_blank" rel="noopener noreferrer">Testex</a>.</li>
            </ul>
          </li>
          <li>
            <strong>Water Repellency (Spray Test - ASTM DWR):</strong>
            <ul>
              <li><strong>Purpose:</strong> Assesses the water resistance of fabrics.</li>
              <li><strong>Procedure:</strong> Water is sprayed onto the fabric's surface, and the amount of water absorbed or repelled is measured.</li>
              <li><strong>Application:</strong> Ensures that fabrics used in outerwear and protective gear can resist water penetration.</li>
            </ul>
          </li>
          <li>
            <strong>Tear Strength (ASTM D12.21):</strong>
            <ul>
              <li><strong>Purpose:</strong> Measures the resistance of fabrics to tearing under specified conditions.</li>
            </ul>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}
