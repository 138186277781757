import React from 'react';
import { Table, Button, FormControl, InputGroup } from 'react-bootstrap';
import './RoutingProduction.css'; // Custom styles

export default function RoutingProduction() {
  return (
    <div className="routing-production-container">
      <div className="header">
        <h5>Routings</h5>
        <div className="header-actions">
          <Button variant="outline-light rounded-pill w-25" className="edit-button">Edit</Button>
          <InputGroup className="search-bar">
            <FormControl
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <Button variant="btn btn-outline-light rounded-pill" style={{width:'80px'}}><img src={require('../../../../../assets/admin/requirement/downloadImage.png')} /></Button>
        </div>
      </div>
      <Table bordered hover variant="dark" className="routing-table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Folder</th>
            <th>Quota</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Helmet Assembly</td>
            <td>Outer Shell</td>
            <td>10 meters</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Outer Shell Construction</td>
            <td>Insulation</td>
            <td>5 sq meters</td>
          </tr>
          <tr className="highlighted-row">
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Thermal Insulation Layering</td>
            <td>Pressure Layer</td>
            <td>1 per suit</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Quality Control and Testing</td>
            <td>Helmet</td>
            <td>1 per suit</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Packaging Shipping Preparation</td>
            <td>Life Support</td>
            <td>1 per suit</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Glove Assembly</td>
            <td>Electronics</td>
            <td>1 per suit</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Boot Assembly</td>
            <td>Undergarment</td>
            <td>1 per suit</td>
          </tr>
          <tr>
          <td><div class="routing-production-radio-circle"></div></td>
            <td>Cooling Garment Installation</td>
            <td>Fasteners</td>
            <td>15 per suit</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
