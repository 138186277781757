import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF ,useFBX, useOBJ} from '@react-three/drei'; // Import GLTF loader
import { useFileContext } from '../../../context/FileContext'; // Import useFileContext
import axios from 'axios'; // Import axios for making API requests
import { API_BASE_URL } from '../../../config/config';
import './UploadModal.css';
import { useLoader } from '@react-three/fiber';
import { useNavigate } from 'react-router-dom'; // For navigation
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

export default function UploadModal({ show, handleClose }) {
  const navigate = useNavigate(); // For navigation
  const [fileType, setFileType] = useState('');
  const [file, setFile] = useState(null);
  const [uploaded, setUploaded] = useState(false);
  const [modelURL, setModelURL] = useState(null); // Store the URL for 3D model
  const [errorMessage, setErrorMessage] = useState(''); // To store validation errors

  const { addFile } = useFileContext(); // Get addFile from context

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
    setFile(null);
    setUploaded(false);
    setModelURL(null); // Reset model URL when file type changes
    setErrorMessage(''); // Clear error message when file type changes
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);

    // Generate URL for model preview if it's a 3D model
    if (fileType === '3dModel') {
      const objectURL = URL.createObjectURL(uploadedFile);
      setModelURL(objectURL);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return;
    }

    try {
      // Prepare data to send
      const formData = new FormData();
      formData.append('fileName', file.name);
      formData.append('fileType', fileType);
      formData.append('file', file);

      // Make the API call to upload the file
      const response = await axios.post(`${API_BASE_URL}/engineering`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Assuming you have token stored in localStorage
        },
      });

      // If upload is successful, update the state
      console.log(response);
      if(response.status === 401) {
        navigate('/login');
      }
      // setUploaded(true);
      handleClose()
      addFile({
        name: file.name,
        type: fileType,
        file: file,
        uploadedAt: new Date().toLocaleString(), // Adding timestamp
      });

      // Display success message or clear the form as needed
      setErrorMessage('');
    } catch (error) {
      // Handle validation errors
      console.log(error)
      if (error.response && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map((err) => err.msg).join(', ');
        setErrorMessage(errorMessages);
      } else {
        setErrorMessage('An error occurred while uploading the file. Please try again.');
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileType('');
    setUploaded(false);
    setModelURL(null);
    setErrorMessage('');
  };

  const renderPreview = () => {
    if (!file) return null;

    if (fileType === 'image') {
      const imageURL = URL.createObjectURL(file);
      return <img src={imageURL} alt="Uploaded Image" style={{ width: '100%' }} />;
    }

    if (fileType === 'video') {
      const videoURL = URL.createObjectURL(file);
      return <video src={videoURL} controls style={{ width: '100%' }} />;
    }

    // For 3D Model, render it using Three.js if the modelURL is available
    if (fileType === '3dModel' && modelURL) {
      return (
        <Canvas style={{ width: '100%', height: '400px' }}>
          <OrbitControls enableZoom={true} />
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <Model modelURL={modelURL} /> {/* Render 3D model */}
        </Canvas>
      );
    }

    return null;
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="engineering-upload-modal">
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {uploaded ? (
          <div>
            <h5>Uploaded {fileType === 'image' ? 'Image' : fileType === 'video' ? 'Video' : '3D Model'} Preview:</h5>
            {renderPreview()}
            <Button variant="danger" className="mt-3" onClick={handleRemoveFile}>
              Remove File
            </Button>
          </div>
        ) : (
          <Form>
            <Form.Group controlId="fileType">
              <Form.Label>Select File Type</Form.Label>
              <Form.Control as="select" value={fileType} onChange={handleFileTypeChange}>
                <option value="">-- Select --</option>
                <option value="image">Image</option>
                <option value="3dModel">3D Model</option>
                <option value="video">Video</option>
              </Form.Control>
            </Form.Group>

            {fileType && (
              <Form.Group controlId="fileUpload" className="mt-3">
                <Form.Label>
                  {fileType === 'image' && 'Select Image'}
                  {fileType === '3dModel' && 'Select 3D Model (.glb or .obj)'}
                  {fileType === 'video' && 'Select Video'}
                </Form.Label>
                <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
            )}

            {errorMessage && <div className="text-danger mt-3">{errorMessage}</div>}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant={uploaded ? 'primary' : 'secondary'} onClick={handleClose}>
          {uploaded ? 'Close' : 'Cancel'}
        </Button>
        {!uploaded && (
          <Button variant="primary" onClick={handleUpload} disabled={!file || !fileType}>
            Upload
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

// Separate component to load and render the 3D model
function Model({ modelURL }) {
  // Get the file extension from the URL
  const fileExtension = modelURL.split('.').pop().toLowerCase();

  // Declare a loader based on the file type
  const model = useLoader(
    fileExtension === 'fbx' ? FBXLoader : GLTFLoader,
    modelURL,
    (loader) => {
      if (fileExtension !== 'fbx') {
        // Only configure DRACOLoader for GLTFLoader
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/draco/'); // Set the path to Draco decoder files
        loader.setDRACOLoader(dracoLoader);
      }
    }
  );

  // Conditionally return the model based on the loaded object
  return <primitive object={model.scene || model} scale={3} />;
}