import React from 'react';
import { useLocation } from 'react-router-dom';
import './TopNavbar.css';
import routes from '../../routes';

function TopNavbar({ toggleSidebar }) {

    const location = useLocation(); // Get the current location
    const currentRoute = routes.find(route => route.layout + route.path === location.pathname);

    return (
        <div className="top-navbar" style={{borderBottom:'3px solid #6d6d6d'}}>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-md-1 text-left'>
                        <button className='toggle-sidebar-btn' onClick={toggleSidebar}>
                            <i className="fas fa-bars"></i>
                        </button>
                    </div>
                    <div className='col-md-5'>
                        <h6 className='header-route-name'>{currentRoute ? currentRoute.name : ''}</h6>
                    </div>
                    <div className='col-md-6 text-center'>
                        <h6>Unified Receptive Production System</h6>
                    </div>
                </div>
            </div>
            <div className="top-navbar-right">
                <img src={require('../../assets/login/images/metakosmos_white_logo.png')} alt="Logo" className='float-right' style={{width:'250px',height:'auto'}} />
            </div>
        </div>
    );
}

export default TopNavbar;
