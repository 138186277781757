import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config/config';

const AdminRoute = ({ children }) => {
  const [isValidToken, setIsValidToken] = useState(null);
  
  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setIsValidToken(false);
        return;
      }

      try {
        // Make API call to check if token is valid
        await axios.get(`${API_BASE_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // If successful, set token validity to true
        setIsValidToken(true);
      } catch (error) {
        // If API call fails, set token validity to false
        console.error('Invalid or expired token.');
        localStorage.removeItem('token'); // Remove invalid token
        setIsValidToken(false);
      }
    };

    checkTokenValidity();
  }, []);

  // While checking the token, render nothing (or a loading spinner)
  if (isValidToken === null) {
    return <div>Loading...</div>;
  }

  // If the token is valid, render the child component; otherwise, redirect to login
  return isValidToken ? children : <Navigate to="/login" />;
};

export default AdminRoute;
