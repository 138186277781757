import React, { useState } from 'react';
import { Button, Dropdown, DropdownButton, Image, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './DesignStages.css';
import DesignProgressBar from '../../../common/DesignProgressBar/DesignProgressBar';
import InitialInspiration from './Stages/InitialInspiration/InitialInspiration';
import DesignStageDropdown from './DesignStageDropdown/DesignStageDropdown';

export default function DesignStages() {
    
    const [activeStage, setActiveStage] = useState("Initial Inspiration");

    const navigate = useNavigate();

    const handleBackClick = () => {
      navigate("/admin/design"); // Programmatically navigate to the upload-design route
    };

    const renderStageContent = () => {
      switch (activeStage) {
        case "Initial Inspiration":
          return <InitialInspiration />;
        case "Tech Pack":
          return <Image src={require('../../../../assets/admin/design/design-stages/TechPackImage.png')} alt="Tech Pack" />;
        case "Final Sketch":
          return <Image src={require('../../../../assets/admin/design/design-stages/FinalSketchImage.png')} alt="Final Sketch" />;
        case "CAD":
          return <Image src={require('../../../../assets/admin/design/design-stages/CadImage.png')} alt="CAD" />;
        case "Renders":
          return <Image src="https://via.placeholder.com/400" alt="Renders" />;
        default:
          return null;
      }
    };
  

  return (
    <div className="design-stages-container">
      {/* Top Navigation */}
      <Row className="align-items-center mb-3 top-nav">
        <Col xs="auto">
          <Button variant="btn-outline-light border text-white rounded-pill mb-2 w-100" onClick={handleBackClick}>Back</Button>
        </Col>
        <Col>
          <span className="">
            Helmet Shell <span className="arrow"> &gt; </span> Visor <span className="arrow"> &gt; </span> Shape and Contour <span className="arrow"> &gt; </span> <span className='text-decoration-underline'>Peripheral Vision Enhancement</span>
          </span>
        </Col>
        <Col xs="auto" className="text-end">
          <span className="top-nav-item">Created in: <a href="#">SolidWorks</a></span>
        </Col>
        <Col xs="auto" className="text-end">
          <span className="top-nav-item">File Types: <a href="#">obj, stl</a></span>
        </Col>
        <Col xs="auto" className="text-end">
          <span className="top-nav-item">Creator: <button className="btn btn-outline-light rounded-pill border border-primary"><img className='rounded-circle pl-2' src={require('../../../../assets/admin/table-images/image2.png')}></img>Ashley X</button></span>
        </Col>
      </Row>

      {/* Test List Dropdown */}
      <Row className="mb-3">
        <Col xs={12}>
        <DesignStageDropdown />
          {/* <DropdownButton id="dropdown-basic-button" title="Test List">
            <Dropdown.Item href="#/action-1">Test 1</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Test 2</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Test 3</Dropdown.Item>
          </DropdownButton> */}
        </Col>
      </Row>

      {/* Main Content */}
      <Row className="design-content">
        {/* Central Dummy Image */}
        <Col md={8} className="image-placeholder d-flex justify-content-center align-items-center">
        <Row className=''>
        <Col md={12} className="image-placeholder d-flex justify-content-center align-items-center">
          {renderStageContent()}
        </Col>
        {/* Progress Bar */}
        <Col md={12}>
          <DesignProgressBar activeStage={activeStage} onSelectStage={setActiveStage} />
        </Col>
      </Row>      
        </Col>

        {/* Right Corner Chat Layout */}
        <Col md={4} className="chat-layout">
        <div className="chat-body">
          <div className="message">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/AlexLImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text">I am thinking this piece should be more like this...</p>
              <Image src={require('../../../../assets/admin/design/chats/ChatsImage1.png')} className="message-image" />
            </div>
          </div>

          <div className="timestamp">02.21.23</div>

          <div className="message">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/AlexLImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text">Let’s setup a meeting to talk about this tmr.</p>
            </div>
          </div>

          <div className="message response">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/YouImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text-tail">Okay, let's do 11:30am</p>
            </div>
          </div>

          <div className="timestamp">Yesterday</div>

          <div className="message">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/MichelleImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text">I really like the subtle curve along the edge. I think that will enhance the experience of the user without them even realizing it. Great job, keep it up!</p>
            </div>
          </div>

          <div className="message response">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/YouImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text">Any thoughts on my progress?</p>
            </div>
          </div>

          <div className="message response highlighted">
            <div className="avatar">
              <Image src={require('../../../../assets/admin/design/chats/AshleyImage.png')} roundedCircle />
            </div>
            <div className="message-content">
              <p className="message-text">It looks great right now. Let’s find a time to chat about potential ridges we can add to help dexterity</p>
            </div>
          </div>
        </div>
        
        <div className="chat-footer">
          <input type="text" placeholder="Add a public comment" className="comment-input" />
          <Button variant="outline-secondary">Post</Button>
        </div>
      </Col>
      </Row>
    </div>
  );
}
