import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './VyomGlovesModal.css';

export default function VyomGlovesModal({ show, handleClose }) {
  return (
    <div className="modal-container">
      <Modal show={show} onHide={handleClose} size="md" centered dialogClassName="vyomgloves-custom-modal">
        <Modal.Header closeButton style={{ border: 'none' }}>
          <Modal.Title className="modal-title">Vyom Gloves v2.1.6</Modal.Title>
        </Modal.Header>
        <div className="home-divider"></div>
        <Modal.Body className="modal-body">
          <div className="gloves-content">
            <p className="gloves-description">
              The Vyom Gloves are a crucial component of the space suit, crafted for optimal dexterity and protection. These gauntlets are not merely accessories; they are your connection to the universe, ensuring precision and safety in every action.
            </p>

            {/* Features Section */}
            <h5 className="section-title">Features:</h5>
            <div className="home-divider"></div>
            <ul className="features-list">
              <li>Integrated Touchscreen Interface</li>
              <li>Thermal Regulation</li>
              <li>Impact Protection</li>
              <li>Radiation Shielding</li>
              <li>Haptic Feedback</li>
              <li>Built-in Sensors</li>
              <li>Pressure Regulation</li>
            </ul>

            {/* Current Progress Section */}
            <h5 className="section-title">Current Progress</h5>
            <div className="home-divider"></div>
            <div className="progress-section">
              {/* Circular Progress Bar */}
              <div className="">
                <svg width="80" height="80" viewBox="0 0 36 36">
                  <path
                    d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831
           a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#ccc"
                    strokeWidth="2"
                  />
                  <path
                    d="M18 2.0845
           a 15.9155 15.9155 0 0 1 0 31.831"
                    fill="none"
                    stroke="#a64dff"
                    strokeWidth="2"
                    strokeDasharray="46, 100"
                  />
                  <text x="18" y="20.35" className="progress-percentage" textAnchor="middle" fill="#fff" fontSize="6">46%</text>
                </svg>
              </div>

              {/* Progress Details */}
              <ul className="progress-details">
                <li><span className="status-circle completed"></span>10 Completed</li>
                <li><span className="status-circle in-progress"></span>5 In Progress</li>
                <li><span className="status-circle unassigned"></span>11 Unassigned</li>
              </ul>

              {/* Progress Assignment Table */}
              <table className="progress-assignments">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Assigned to</th>
                    <th></th> {/* Empty column for the right-angle arrow */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Touch Screen Interface</td>
                    <td><span className="status-dot completed"></span></td>
                    <td className="assigned-to">
                      <div className='assigned-name-profile'>
                        <img src={require('../../../../../assets/admin/table-images/image3.png')} alt="Alex L." className="profile-pic" />
                        <span className="assignee-name">Alex L.</span>
                      </div>
                    </td>
                    <td><i className="fa fa-angle-right arrow-icon"></i></td>
                  </tr>
                  <tr>
                    <td>Thermal Regulation</td>
                    <td><span className="status-dot in-progress"></span></td>
                    <td className="assigned-to">
                      <div className='assigned-name-profile'>
                        <img src={require('../../../../../assets/admin/table-images/image2.png')} alt="Michelle P." className="profile-pic" />
                        <span className="assignee-name">Michelle P.</span>
                      </div>
                    </td>
                    <td><i className="fa fa-angle-right arrow-icon"></i></td>
                  </tr>
                  <tr>
                    <td>Impact Protection</td>
                    <td><span className="status-dot unassigned"></span></td>
                    <td className="assigned-to">
                      <div className='assigned-name-profile'>
                        <img src={require('../../../../../assets/admin/table-images/image3.png')} alt="Ashley K." className="profile-pic" />
                        <span className="assignee-name">Ashley K.</span>
                      </div>
                    </td>
                    <td><i className="fa fa-angle-right arrow-icon"></i></td>
                  </tr>
                  <tr>
                    <td>Sensor Locations</td>
                    <td><span className="status-dot completed"></span></td>
                    <td className="assigned-to">
                      <div className='assigned-name-profile'>
                        <img src={require('../../../../../assets/admin/table-images/image2.png')} alt="Ashley K." className="profile-pic" />
                        <span className="assignee-name">Ashley K.</span>
                      </div>
                    </td>
                    <td><i className="fa fa-angle-right arrow-icon"></i></td>
                  </tr>
                </tbody>
              </table>

              <Button variant="link" className="view-more">View 15 more...</Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
