import React from 'react';
import { Container, Row, Col, Button, Dropdown, ButtonGroup, Image } from 'react-bootstrap';
import './EngineeringScreen.css';

export default function EngineeringScreen() {
  return (
    <Container fluid className="engineering-screen-container">
      {/* Top Section */}
      <div className="engineering-screen-top-section">
        <Button variant='btn btn-outline-light rounded-pill'>Back</Button>

        <Dropdown as={ButtonGroup} className="engineering-custom-dropdown lg">
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            Thermal Test
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Thermal Test</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Structural Analysis</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Fatigue Test</Dropdown.Item>
            <Dropdown.Item href="#/action-4">Pressure Test</Dropdown.Item>
            <Dropdown.Item href="#/action-5">Finite Element Analysis</Dropdown.Item>
            <Dropdown.Item href="#/action-6">Impact Test</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <div className="breadcrumbs">
          <span>Helmet Shell</span> &gt; <span>Visor</span> &gt; <span>Shape and Contour</span> &gt; <span className="active">Visor Piece</span>
        </div>

        <div className="metadata">
          <span>Created in: <a href="#solidworks">SolidWorks</a></span>
          <span>File Type: <a href="#obj">.obj</a></span>
          Creator:<span className="creator"> <span>&nbsp;&nbsp;&nbsp;<Image src={require('../../../../assets/admin/table-images/image2.png')} roundedCircle alt="Alex L." /> Alex L.</span></span>
        </div>
      </div>
      <div className='home-divider'></div>
      {/* Middle Section */}
      <Row className="middle-section">
        <Col md={2} className="color-scale mt-5">
          <div className="color-bar">
            <div className="color-label">25062.72</div>
            <div className="color-label">1783.35</div>
            <div className="color-label">1605.92</div>
            <div className="color-label">1327.05</div>
            <div className="color-label">1239.59</div>
            <div className="color-label">1070.62</div>
            <div className="color-label">892.18</div>
            <div className="color-label">713.75</div>
            <div className="color-label">535.31</div>
            <div className="color-label">356.87</div>
            <div className="color-label">178.93</div>
            <div className="color-label">0.00</div>
          </div>
        </Col>
        <Col md={10} className="image-section">
          <img src={require('../../../../assets/admin/engineering/VideoImage.png')} alt="Dummy Model" className="model-image" />
        </Col>
      </Row>

      {/* Bottom Section */}
      <div className="bottom-section">
        <div className="range-container">
          <span className="time-label start-time">0.00</span>
          <input type="range" min="0" max="100" className="range-slider" />
          <span className="time-label end-time">3.23</span>
        </div>
        <div className="play-controls">
          <Image src={require('../../../../assets/admin/engineering/PlayButton.png')} />
          {/* <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
          <circle cx="35" cy="35" r="2" stroke="white" stroke-width="2" fill="none"/>
          <path d="M30 15 A15 15 0 0 0 15 25" stroke="white" stroke-width="2" fill="none" stroke-linecap="round"/>
          <path d="M15 25 L18 22 L18 28 Z" fill="white"/>
          <text x="25" y="31" fill="white" font-family="Arial" font-size="10" text-anchor="middle">10</text>
        </svg>



<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25" r="25" fill="none"/>
  <polygon points="20,15 35,25 20,35" fill="white"/>
</svg>
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="25" cy="25" r="25" fill="none"/>
  <path d="M27 15V18.5C27 21.5376 29.4624 24 32.5 24H34" stroke="#FFFFFF" stroke-width="2"/>
  <path d="M34 26L34 24L42 24L42 26L42 28L34 28L34 26Z" fill="white"/>
  <text x="25" y="30" fill="white" font-family="Arial" font-size="12">10</text>
  <path d="M26.5 31C25.6716 31 25 30.3284 25 29.5C25 28.6716 25.6716 28 26.5 28C27.3284 28 28 28.6716 28 29.5C28 30.3284 27.3284 31 26.5 31Z" fill="white"/>
</svg> */}
          {/* <Button variant="link" className="play-button rewind-button">⟲</Button>
    <Button variant="link" className="play-button play-pause-button">▶️</Button>
    <Button variant="link" className="play-button forward-button">⟳</Button> */}
        </div>
      </div>

    </Container>
  );
}
