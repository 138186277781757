
import React from 'react';
import { Container, Row, Col, Form, Button,FormControl } from 'react-bootstrap';
import './Routing.css';
import { useNavigate, useLocation } from 'react-router-dom';
import RoutingProduction from './RoutingProduction/RoutingProduction';
import ThermalInsulationLayering from './ThermalInsulationLayering/ThermalInsulationLayering';
export default function Routing() {
    
    const navigate = useNavigate(); // For navigation

    const location = useLocation(); 

    const isActive = (path) => location.pathname === path;

    const handleRouteNavigation = (value) => {

        if(value === 'bill'){
            navigate('/admin/production-bill-of-materials')
        }else if(value === 'order') {
            navigate('/admin/production-order');
        }else{
            navigate('/admin/production-routing');
        }
        
    }

  return (
    <Container fluid className="routing-container">
            {/* Top Navigation Section */}
            <Row className="top-navigation">
                <Col md={12}>
                    <div className="breadcrumb-routing">
                        <span className="breadcrumb-item-routing" onClick={() =>handleRouteNavigation('bill')}>Bill of Materials</span>
                        <span className="breadcrumb-arrow-routing"><i class="routing-arrow-icon"></i></span>
                        <span className={`breadcrumb-item-routing ${isActive('/admin/production-routing') ? 'active' : ''}`} onClick={() => handleRouteNavigation('routing')}>Routings</span>
                        <span className="breadcrumb-arrow-routing"><i class="routing-arrow-icon"></i></span>
                        <span className="breadcrumb-item-routing" onClick={() => handleRouteNavigation('order')}>Production Orders</span>
                    </div>
                </Col>
            </Row>

            {/* Main Content Section */}
            <Row className="">
                <Col md={6} className="">
                    <Row>
                        <Col md={12} className='mb-4'>
                            <RoutingProduction />
                        </Col>
                        <Col className='production-form-routing mt-5' md={12}>
                            <h5 className='text-white'>Add</h5>
                            <Form className="add-form">
                                <div className="form-header">
                                    <span>Routing</span> / <span>Production Order</span>
                                </div>
                                <Form.Group controlId="formName" className="form-group">
                                    <Form.Label>Name</Form.Label>
                                    <FormControl type="text" placeholder="Gauntlet Fabrication" className="form-input" />
                                </Form.Group>
                                <Form.Group controlId="formFolder" className="form-group">
                                    <Form.Label>Folder</Form.Label>
                                    <Form.Control as="select" className="form-input">
                                        <option>Select Folder</option>
                                        <option>Folder 1</option>
                                        <option>Folder 2</option>
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group controlId="formComments" className="form-group">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} className="form-input" />
                                </Form.Group>
                                <div className="form-buttons">
                                    <Button variant="outline-light" className="clear-button">Clear</Button>
                                    <Button variant="primary" className="save-button">Save</Button>
                                </div>
                            </Form>
                        </Col>
                    </Row>
                </Col>

                <Col md={6} className="finished-products-container-thermal">
                <h5 className="section-title">Thermal Insulation Layering</h5>
                    <ThermalInsulationLayering />
                </Col>
            </Row>


        </Container>
  )
}
