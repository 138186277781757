import React from 'react';
import './DesignProgressBar.css';

const DesignProgressBar = ({ activeStage, onSelectStage }) => {
  const stages = ['Initial Inspiration', 'Tech Pack', 'Final Sketch', 'CAD', 'Renders'];

  const handleClick = (stage) => {
    onSelectStage(stage);
  };

  return (
    <div className="progress-container">
      {stages.map((stage, index) => (
        <div key={index} className="progress-step">
          <div
            className={`progressbar-circle ${stages.indexOf(activeStage) > index ? 'active' : ''}`}
            onClick={() => handleClick(stage)}
          ></div>
          {index < stages.length - 1 && (
            <div
              className={`progress-line ${stages.indexOf(activeStage) > index ? 'active-line' : ''}`}
            ></div>
          )}
          <p className={`stage-label m-4 ${stage === activeStage ? 'active-label' : ''}`}>
            {stage}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DesignProgressBar;
