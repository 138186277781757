import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Button, Form, Table, Modal } from 'react-bootstrap';
import './NewRequirement.css';

import { API_BASE_URL } from '../../../../config/config';

export default function NewRequirement() {
  const [requirements, setRequirements] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [subdivisions, setSubdivisions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    division_id: '',
    subdivision_id: '',
    source: '',
    file_type: '',
    file: null,
  });
  const [selectedRequirement, setSelectedRequirement] = useState(null);

  const API_URL = `${API_BASE_URL}/requirements`;
  const DIVISION_API_URL = `${API_BASE_URL}/requirements/divisions`;
  const SUBDIVISION_API_URL = `${API_BASE_URL}/requirements/subdivisions`;

  // Fetch requirements
  const fetchRequirements = async () => {
    try {

      const token =  localStorage.getItem('token');
      const response = await axios.get(API_URL , {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
      setRequirements(response.data);
    } catch (error) {
      console.error('Error fetching requirements:', error);
    }
  };

  // Fetch divisions for the dropdown
  const fetchDivisions = async () => {
    try {
      const response = await axios.get(DIVISION_API_URL);
      setDivisions(response.data);
    } catch (error) {
      console.error('Error fetching divisions:', error);
    }
  };

  // Fetch subdivisions based on selected division
  const fetchSubdivisions = async (divisionId) => {
    try {
      const response = await axios.get(`${SUBDIVISION_API_URL}?divisionId=${divisionId}`);
      setSubdivisions(response.data);
    } catch (error) {
      console.error('Error fetching subdivisions:', error);
    }
  };

  useEffect(() => {
    fetchRequirements();
    fetchDivisions();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the division is selected, fetch corresponding subdivisions
    if (name === 'division_id') {
      setFormData({ ...formData, [name]: value, subdivision_id: '' });
      fetchSubdivisions(value);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle file input change
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  // Handle adding or updating a requirement
  const handleSaveRequirement = async () => {
    const requestData = new FormData();
    requestData.append('name', formData.name);
    requestData.append('division_name', formData.division_id);
    requestData.append('subdivision_name', formData.subdivision_id);
    requestData.append('source', formData.source);
    requestData.append('file_type', formData.file_type);
    requestData.append('file', formData.file);

    try {
        const token =  localStorage.getItem('token');
      if (selectedRequirement) {
        // Update existing requirement
        await axios.put(`${API_URL}/${selectedRequirement._id}`, requestData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : `Bearer ${token}`
          },
        });
      } else {
        // Create new requirement
       
        await axios.post(API_URL, requestData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization' : `Bearer ${token}`
          },
        });
      }
      fetchRequirements();
      setShowModal(false);
      setSelectedRequirement(null);
      setFormData({
        name: '',
        division_id: '',
        subdivision_id: '',
        source: '',
        file_type: '',
        file: null,
      });
    } catch (error) {
      console.error('Error saving requirement:', error);
    }
  };

  // Handle editing a requirement
  const handleEditRequirement = (requirement) => {
    setSelectedRequirement(requirement);
    setFormData({
      name: requirement.name,
      division_id: requirement.division_name._id,
      subdivision_id: requirement.subdivision_name._id,
      source: requirement.source,
      file_type: requirement.file_type,
      file: null,
    });
    fetchSubdivisions(requirement.division_name._id);
    setShowModal(true);
  };

  // Handle deleting a requirement
  const token = localStorage.getItem('token');

const handleDeleteRequirement = async (id) => {
    try {
        await axios.delete(`${API_URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        fetchRequirements();
    } catch (error) {
        console.error('Error deleting requirement:', error);
    }
};


  return (
    <Container fluid className="requirement-container">
      <Row className="top-section">
        <Col md={6}>
          <h4>Requirements</h4>
        </Col>
        <Col md={6} className="d-flex justify-content-end align-items-center requirement-buttons">
          <Button variant="light" onClick={() => setShowModal(true)}>
            Create New
          </Button>
        </Col>
      </Row>
      <div className="requirement-divider mb-4"></div>

      <Table responsive className="requirement-table">
        <thead>
          <tr>
            <th>Division Name</th>
            <th>Sub Division</th>
            <th>Source</th>
            <th>Name</th>
            <th>File Type</th>
            <th>File</th>
            <th>Creator</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {requirements.map((requirement) => (
            <tr key={requirement._id}>
              <td>{requirement.division_name?.division_name}</td>
              <td>{requirement.subdivision_name?.subdivision_name}</td>
              <td>{requirement.source}</td>
              <td>{requirement.name}</td>
              <td>{requirement.file_type}</td>
              {/* <td>{requirement.file_name}</td> */}
              <td><img src={requirement.file_urL} height={100} width={100} /></td>
              <td>{requirement.created_by?.first_name} 
                {/* {requirement.created_by?.last_name} */}
                </td>
              <td>
                <Button
                  variant="outline-light"
                  size="sm"
                  onClick={() => handleEditRequirement(requirement)}
                  className="m-1"
                >
                  Edit
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={() => handleDeleteRequirement(requirement._id)}
                  className="m-1"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for Create/Edit Requirement */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedRequirement ? 'Edit Requirement' : 'Create Requirement'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="requirementName">
              <Form.Label>Requirement Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="divisionId" className="mt-3">
              <Form.Label>Division</Form.Label>
              <Form.Control
                as="select"
                name="division_id"
                value={formData.division_id}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Division</option>
                {divisions.map((division) => (
                  <option key={division._id} value={division._id}>
                    {division.division_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="subdivisionId" className="mt-3">
              <Form.Label>Sub-Division</Form.Label>
              <Form.Control
                as="select"
                name="subdivision_id"
                value={formData.subdivision_id}
                onChange={handleInputChange}
                required
                disabled={!formData.division_id} // Disable dropdown if no division is selected
              >
                <option value="">Select Sub-Division</option>
                {subdivisions.map((subdivision) => (
                  <option key={subdivision._id} value={subdivision._id}>
                    {subdivision.subdivision_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="source">
              <Form.Label>Source</Form.Label>
              <Form.Control
                type="text"
                name="source"
                value={formData.source}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="fileType" className="mt-3">
              <Form.Label>File Type</Form.Label>
              <Form.Control
                as="select"
                name="file_type"
                value={formData.file_type}
                onChange={handleInputChange}
                required
              >
                <option value="">Select File Type</option>
                <option value="image">Image</option>
                <option value="video">Video</option>
                <option value="3dModel">3D Model</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="file" className="mt-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                type="file"
                name="file"
                onChange={handleFileChange}
                required={!selectedRequirement} // File is required only for new entries
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveRequirement}>
            {selectedRequirement ? 'Update' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
