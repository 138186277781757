import React from 'react';
import './QualityTestingManually.css';

export default function QualityTestingManually() {
  return (
    <div className="quality-testing-manual-container">
      {/* Back Button */}
      <div className="back-button-container">
        <button className="btn btn-outline-light manually-back-button">Back</button>
      </div>

      {/* Title */}
      <h5 className="form-title">Add Test</h5>

      {/* Row with Two Columns */}
      <div className="row m-auto">
        {/* First Column - Form */}
        <div className="col-md-6 form-section">
          <h6>Basic Information</h6>
          <form className="quality-form">
            <label>Title</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Description</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Folder</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Date of Test</label>
            <input type="date" className="form-control manually-custom-input" />

            <label>Test Location</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Test Conducted By</label>
            <input type="text" className="form-control manually-custom-input" />

            <h3>Data</h3>

            <label>Data Title #1</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Data Value #1</label>
            <input type="text" className="form-control manually-custom-input" />

            <label>Data Title #2</label>
            <input type="text" className="form-control manually-custom-input" />
          </form>

          <div className="form-buttons">
            <button className="btn save-draft-button btn btn-outline-light">Save as Draft</button>
            <button className="btn save-button bg-white">Save</button>
          </div>
        </div>


        {/* Second Column - File Upload Section */}
        <div className="col-md-6 file-upload-section">
          <div className="file-upload-box">
            <p className="file-upload-title">Drop your files here</p>
            <div className="file-upload-icon">↓</div>
            <p className="accepted-formats">
              Accepted formats:
              <br />
              .pdf, <br /> .doc, <br /> .docx, <br /> .xls, <br /> .xlsx, <br /> .csv, <br /> .ods, <br /> .xml, <br /> .json, <br /> .txt, <br /> .html, <br /> .htm
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
